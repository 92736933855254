import React from "react";
import { TextField, Box, Button } from "@mui/material";

const DynamicForm = ({ formData }) => {
  const { name, description, fields } = formData;

  const renderField = (field) => {
    return (
      <Box
        key={field.label}
        sx={{
          bgcolor: "white",
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "20px", // Space between fields
          boxShadow: 2, // Slight shadow for depth
        }}
      >
        <label style={{ display: "block", marginBottom: "8px", fontWeight: "bold" }}>
          {field.label}
        </label>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          type={field.type === "phoneNumber" ? "tel" : field.type}
          placeholder={`Enter ${field.label}`} // Added placeholder
          InputProps={{
            sx: {
              borderRadius: "4px",
              height: "40px", // Set medium height
              fontSize: "14px", // Medium font size
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3f51b5",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3f51b5",
              },
            },
          }}
        />
      </Box>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  return (
    <Box
      sx={{
        padding: "40px",
        maxWidth: "600px",
        margin: "20px auto",
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: 3,
          marginBottom: "20px", // Space between title/description box and fields
        }}
      >
        <h2 style={{ color: "#3f51b5" }}>{name}</h2>
        <p style={{ color: "#666" }}>{description}</p>
      </Box>

      <form onSubmit={handleSubmit}>
        {fields.map(renderField)}
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              height: "40px", // Set medium height for button
              fontSize: "14px", // Medium font size for button
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default DynamicForm;
