import React, { useState, useEffect } from 'react';
import BaseTable, { Column } from 'react-base-table';
import 'react-base-table/styles.css';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'; // Import Ant Design icons

const BaseTableComponent = ({ data, enableCheckboxes, enableActions, onEdit, onDelete }) => {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [tableWidth, setTableWidth] = useState(0); // State to hold the calculated width
  const [columns, setColumns] = useState([]); // State for columns

  useEffect(() => {
    // Calculate the table width as 80% of the window width
    const calculateWidth = () => {
      const width = window.innerWidth * 0.8; // 80% of the window width
      setTableWidth(width);

      // Define columns and calculate their widths
      if (data.length > 0) {
        const newColumns = Object.keys(data[0]).map((key) => ({
          key,
          dataKey: key,
          title: key.charAt(0).toUpperCase() + key.slice(1),
          width: width / (Object.keys(data[0]).length + (enableCheckboxes ? 1 : 0) + (enableActions ? 2 : 0)), // Adjusted width calculation
        }));
        setColumns(newColumns);
      }
    };

    calculateWidth(); // Initial calculation
    window.addEventListener('resize', calculateWidth); // Update on resize

    return () => {
      window.removeEventListener('resize', calculateWidth); // Cleanup
    };
  }, [data, enableCheckboxes, enableActions]);

  // Set a fixed row height
  const fixedRowHeight = 60; // Change this value to increase the height

  // Calculate table height based on the number of rows
  const tableHeight = data.length * fixedRowHeight + 50; // 50 for header and padding

  const handleCheckboxChange = (rowId) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(rowId)) {
      newSelectedRows.delete(rowId);
    } else {
      newSelectedRows.add(rowId);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = data.map(row => row.id);
      setSelectedRows(new Set(allIds));
    } else {
      setSelectedRows(new Set());
    }
  };

  return (
    <BaseTable
      data={data}
      width={tableWidth} // Set the calculated width
      height={tableHeight}
      rowHeight={fixedRowHeight} // Set a fixed row height
    >
      {enableCheckboxes && (
        <Column
          key="checkbox"
          dataKey="checkbox"
          width={50}
          title={
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={selectedRows.size === data.length}
              indeterminate={selectedRows.size > 0 && selectedRows.size < data.length}
            />
          }
          cellRenderer={({ rowData }) => (
            <input 
              type="checkbox" 
              id={`checkbox-${rowData.id}`} 
              checked={selectedRows.has(rowData.id)} 
              onChange={() => handleCheckboxChange(rowData.id)} 
            />
          )}
        />
      )}

      {columns.map((column) => (
        <Column
          key={column.key}
          dataKey={column.dataKey}
          width={column.width} // Use the calculated column width
          title={column.title}
        />
      ))}

      {enableActions && (
        <>
          <Column
            key="edit"
            dataKey="edit"
            width={50}
            title="Edit"
            cellRenderer={({ rowData }) => (
              <button 
                style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                onClick={() => onEdit(rowData.id)} // Use the onEdit function passed via props
              >
                <AiOutlineEdit size={24} color="blue" /> {/* Updated to Ant Design edit icon */}
              </button>
            )}
          />
          <Column
            key="delete"
            dataKey="delete"
            width={50}
            title="Delete"
            cellRenderer={({ rowData }) => (
              <button 
                style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                onClick={() => onDelete(rowData.id)} // Use the onDelete function passed via props
              >
                <AiOutlineDelete size={24} color="red" /> {/* Updated to Ant Design delete icon */}
              </button>
            )}
          />
        </>
      )}
    </BaseTable>
  );
};

export default BaseTableComponent;
