import axios from 'axios';
import API_URL from '../config/config';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Ajouter un intercepteur pour inclure le token dans toutes les requêtes
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Intercepter les réponses pour gérer les erreurs d'authentification
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token'); // Supprimer le token invalide
      window.location.href = '/'; // Rediriger vers la page de connexion
    }
    return Promise.reject(error);
  }
);

export default instance;
