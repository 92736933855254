import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Box, Typography, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; // Importer l'icône d'informations
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the components
Chart.register(ArcElement, Tooltip, Legend);

const PieChartCard = ({ title, data }) => {
  return (
    <Box
      sx={{
        minWidth:'auto',
        height: "455px",
        margin: 1,
        padding: 3,
        borderRadius: 4,
        backgroundColor: '#353a50',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography variant="h6" sx={{ flexGrow: 1,color:'white' }}>
          {title}
        </Typography>
        <InfoIcon sx={{ color: 'white', marginLeft: 1 }} /> {/* Icône d'informations */}
      </Box>
      <Divider sx={{ marginY: 1 }} /> {/* Ligne séparatrice */}
      <div style={{width:'100%',height:'90%'}}>
      <Pie 
        data={data} 
        options={{
          maintainAspectRatio: false,
          cutout: '70%', // Crée l'effet donut
          plugins: {
            legend: {
              position: 'top',
            },
          },
        }} 
      />
      </div>
    </Box>
  );
};

export default PieChartCard;
