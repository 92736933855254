import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Typography, Modal, Box, Button, TextField, Switch, MenuItem } from "@mui/material";
import ReactQuill from "react-quill"; // Ensure you have this installed
import axios from "../Services/axios";
import './Calendar.scss';
import API_URL from '../config/config';

const localizer = momentLocalizer(moment);

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [message, setMessage] = useState("");

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${API_URL}/leads`);
      const leads = response.data.data;

      const formattedEvents = leads.map(lead => ({
        title: lead.note.replace(/<[^>]*>/g, ''),
        start: new Date(lead.remind_date),
        end: new Date(lead.remind_date),
        id: lead.id,
      }));

      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleSelectEvent = async (event) => {
    try {
      const response = await axios.get(`${API_URL}/leads/${event.id}`);
      setSelectedLead(response.data);
      setMessage(response.data.note || "");
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  const handleCloseModal = async () => {
    if (selectedLead) {
      const leadId = selectedLead.id;
      const updatedLeadData = {
        reminded: selectedLead.reminded,
        remind_date: selectedLead.remind_date,
        note: message,
        status: selectedLead.status
      };

      try {
        await axios.put(`${API_URL}/leads/${leadId}`, updatedLeadData);
        console.log("Lead updated successfully");
      } catch (error) {
        console.error("Error updating lead:", error);
      }
    }

    setModalOpen(false);
    setSelectedLead(null);
    setMessage("");
    
    // Refresh the events after closing the modal
    fetchEvents();
  };

  const handleRemindedChange = (event) => {
    const isChecked = event.target.checked;
    setSelectedLead((prev) => ({
      ...prev,
      reminded: isChecked ? 1 : 0,
      remind_date: isChecked ? prev.remind_date : "",
    }));
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setSelectedLead((prev) => ({ ...prev, status: newStatus }));
  };

  return (
    <Box sx={{ p: 4 }}>
  <Typography variant="h4" gutterBottom>
    Calendrier
  </Typography>
  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "95%", margin: "0 auto" }}>
    
    <div style={{ width: "98%", marginTop: "35px", margin: "0 auto" }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={handleSelectEvent}
        style={{ height: 700 }}
        defaultView="month"
      />
    </div>

    <Modal open={modalOpen} onClose={handleCloseModal}>
      <Box sx={modalStyle}>
        {selectedLead && (
          <>
            <Typography variant="h6">Détails du Lead</Typography>
            <Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {selectedLead.lead_data &&
                  selectedLead.lead_data.map((field) => (
                    <TextField
                      key={field.id}
                      label={field.field_name}
                      value={field.value}
                      fullWidth
                      margin="normal"
                      InputProps={{ readOnly: true }}
                      sx={{ flexBasis: "45%" }}
                    />
                  ))}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography variant="body1">Rappel :</Typography>
                <Switch
                  checked={selectedLead.reminded === 1}
                  onChange={handleRemindedChange}
                />
                <TextField
                  label="Date et Heure de Rappel"
                  type="datetime-local"
                  InputLabelProps={{ shrink: true }}
                  value={selectedLead.reminded === 1 ? selectedLead.remind_date : ""}
                  onChange={(e) =>
                    setSelectedLead((prev) => ({
                      ...prev,
                      remind_date: e.target.value,
                    }))
                  }
                  fullWidth
                  margin="normal"
                  InputProps={{ readOnly: selectedLead.reminded !== 1 }}
                  sx={{ marginLeft: 2 }}
                />
                <TextField
                  label="Statut"
                  select
                  value={selectedLead.status || ""}
                  onChange={handleStatusChange}
                  fullWidth
                  sx={{ marginLeft: 2 }}
                >
                  <MenuItem value="interested">Intéressé</MenuItem>
                  <MenuItem value="not interested">Pas intéressé</MenuItem>
                  <MenuItem value="no response">Pas de réponse</MenuItem>
                </TextField>
              </Box>
              <Box sx={{ mb: 7 }}>
                <ReactQuill
                  theme="snow"
                  value={message}
                  onChange={setMessage}
                  style={{ height: "200px", margin: "10px 0" }}
                />
              </Box>
            </Box>
            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Enregistrer
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
    </div>
  </Box>

  );
};

export default CalendarPage;
