import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { NavLink, useNavigate } from "react-router-dom"; // Use useNavigate for navigation
import { FaSignOutAlt, FaBars } from "react-icons/fa";
import axios from "../Services/axios";
import API_URL from "../config/config";
import "./SideBar.css";
import { ReactComponent as CampaignIcon } from "../Icons/CampaignIcon.svg";
import { ReactComponent as DashboardIcon } from "../Icons/DashboardIcon.svg";
import { ReactComponent as FormIcon } from "../Icons/FormIcon.svg";
import { ReactComponent as LeadIcon } from "../Icons/LeadIcon.svg";
import { ReactComponent as MessageIcon } from "../Icons/MessageIcon.svg";
import { ReactComponent as SettingsIcon } from "../Icons/SettingsIcon.svg";
import { ReactComponent as CalendarIcon } from "../Icons/CalendarIcon.svg";
import AtlasLead from "../Icons/logo.jpg";

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [userRole, setUserRole] = useState(null); // Start with null until role is fetched
  const [loading, setLoading] = useState(true); // Track loading state
  const navigate = useNavigate(); // Hook for navigation

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token
    navigate('/'); // Redirect to login page
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCollapsed(true); // Auto-collapse on small screens
      } else {
        setCollapsed(false); // Expand on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch the user role when the component mounts
    axios
      .get(`${API_URL}/account/getMyRole`)
      .then((response) => {
        const { type, roles } = response.data;
        if (type === "user" && roles.includes("staff")) {
          setUserRole("staff"); // Set role as 'staff'
        } else {
          setUserRole("admin"); // Set default role to 'admin' if it's not staff
        }
      })
      .catch((error) => {
        console.error("Error fetching role:", error);
        setUserRole("admin"); // Default to admin if error occurs
      })
      .finally(() => {
        setLoading(false); // Set loading to false once the API call is complete
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading until the role is fetched
  }

  return (
    <div className="sidebar-container">
      <div className="collapse-icon" onClick={toggleSidebar}>
        <FaBars size={24} color="#6b5b95" />
      </div>
      <Sidebar
        collapsed={collapsed}
        className={`custom-sidebar ${collapsed ? "collapsed" : ""}`}
      >
        <Menu
          menuItemStyles={{
            button: {
              color: "white",
              margin: collapsed ? "0" : "7px 10px",
              "&:hover": {
                color: "white",
                background: "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                borderRadius: "11px",
              },
              "&.active": {
                color: "white",
                background: "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                borderRadius: "11px",
              },
            },
          }}
        >
          <MenuItem
            icon={
              <img
                src={AtlasLead}
                alt="Atlas Lead"
                style={{ width: 200, height: 70 }}
              />
            }
            rootStyles={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#6b5b95",
              backgroundColor: "transparent",
              fontWeight: "bold",
              paddingBottom: 40,
              paddingTop: 20,
              fontSize: 24,
            }}
          />
          
          {/* Common Menu Item: Always visible */}
          <MenuItem
            icon={<DashboardIcon fill="white" />}
            component={<NavLink to="/Dashboard" activeClassName="active-link" />}
          >
            Accueil
          </MenuItem>

          {/* Conditional rendering based on userRole */}
          {userRole !== "staff" && (
            <>
              <MenuItem
                icon={<CampaignIcon fill="white" />}
                component={<NavLink to="/Campains" activeClassName="active-link" />}
              >
                Campagnes
              </MenuItem>
              <MenuItem
                icon={<FormIcon fill="white" />}
                component={<NavLink to="/FormPage" activeClassName="active-link" />}
              >
                Formulaires
              </MenuItem>
              <MenuItem
                icon={<LeadIcon fill="white" />}
                component={<NavLink to="/Leads" activeClassName="active-link" />}
              >
                Leads
              </MenuItem>
              <MenuItem
                icon={<MessageIcon fill="white" />}
                component={<NavLink to="/EmailTemplates" activeClassName="active-link" />}
              >
                Modèles d'Email
              </MenuItem>
              <SubMenu label="Modèle de Message" icon={<MessageIcon fill="white" />}>
                <MenuItem component={<NavLink to="/SMSTemplatesPage" activeClassName="active-link" />}>
                  Modèles de SMS
                </MenuItem>
              </SubMenu>
              <MenuItem
                icon={<CalendarIcon fill="white" />}
                component={<NavLink to="/Calendar" activeClassName="active-link" />}
              >
                Calendrier
              </MenuItem>
              <SubMenu label="Paramètres" icon={<SettingsIcon fill="white" />}>
                <MenuItem component={<NavLink to="/GeneralSettings" activeClassName="active-link" />}>
                  Paramètres généraux
                </MenuItem>
                <MenuItem component={<NavLink to="/UserManagementPage" activeClassName="active-link" />}>
                  Gestion des rôles/utilisateurs
                </MenuItem>
              </SubMenu>
            </>
          )}

          {/* Menu items that are always visible for "staff" users */}
          {userRole === "staff" && (
            <>
              <MenuItem
                icon={<LeadIcon fill="white" />}
                component={<NavLink to="/Leads" activeClassName="active-link" />}
              >
                Leads
              </MenuItem>
              <MenuItem
                icon={<CalendarIcon fill="white" />}
                component={<NavLink to="/Calendar" activeClassName="active-link" />}
              >
                Calendrier
              </MenuItem>
            </>
          )}

          {/* Logout */}
          <MenuItem
            icon={<FaSignOutAlt color="white" size={20} />}
            onClick={handleLogout} // Call handleLogout on click
          >
            Se déconnecter
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SideBar;
