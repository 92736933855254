import React, { useState } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'; // Icons for actions
import { FaFacebook, FaGlobe } from 'react-icons/fa'; // Icons for Facebook and Web
import StatusBox from './StatusBox'; // Import the new StatusBox component
import './DynamicTable.css';

const DynamicTableLead = ({ data, enableCheckboxes, enableActions, onEdit, onDelete, onRowClick }) => {
  const [selectedRows, setSelectedRows] = useState(new Set());

  const handleCheckboxChange = (rowId, e) => {
    e.stopPropagation(); // Stop the row click when toggling the checkbox
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(rowId)) {
      newSelectedRows.delete(rowId);
    } else {
      newSelectedRows.add(rowId);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = data.map((row) => row.id);
      setSelectedRows(new Set(allIds));
    } else {
      setSelectedRows(new Set());
    }
  };

  if (data.length === 0) {
    return <p>No Data available...</p>;
  }

  const allKeys = [...new Set(data.flatMap(Object.keys))].filter(key => key !== 'Confirmation');

  const formatDateToMorocco = (utcDate) => {
    const date = new Date(utcDate);
    date.setHours(date.getHours() + 1);
    return date.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  };

  const getShortMessageHTML = (message) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = message; // Convert HTML to text
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.split(' ');
    const shortText = words.slice(0, 20).join(' ');
    return shortText + (words.length > 20 ? '...' : '');
  };

  const renderMessageHTML = (message) => {
    const shortMessage = getShortMessageHTML(message);
    return `<span>${shortMessage}</span>`;
  };

  const renderSourceIcon = (source) => {
    if (source === 'Web') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaGlobe size={20} />
          <span style={{ marginLeft: '8px' }}>Website</span>
        </div>
      );
    } else if (source === 'Facebook') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaFacebook size={20} color="blue" />
          <span style={{ marginLeft: '8px' }}>Facebook</span>
        </div>
      );
    }
    return null;
  };

  const handleRowClick = (row, e) => {
    if (e.target.closest('input[type="checkbox"]')) {
      // If the click is on a checkbox, don't trigger row click logic
      return;
    }
    // Trigger row click logic here if needed, like opening a modal or other actions
    if (onRowClick) onRowClick(row);
  };

  return (
    <table className="custom-table">
      <thead>
        <tr>
          {enableCheckboxes && (
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedRows.size === data.length}
                indeterminate={selectedRows.size > 0 && selectedRows.size < data.length}
              />
            </th>
          )}
          <th>Confirmation</th>
          {allKeys.map((key) => (
            <th key={key}>{key.charAt(0).toLowerCase() + key.slice(1)}</th>
          ))}
          {enableActions && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id} onClick={(e) => handleRowClick(row, e)}>
            {enableCheckboxes && (
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.has(row.id)}
                  onChange={(e) => handleCheckboxChange(row.id, e)} // Stop propagation here
                />
              </td>
            )}
            <td>
              {row.confirmation === 0 ? (
                <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'orange', borderRadius: '50%' }}></span>
              ) : (
                <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'green', borderRadius: '50%' }}></span>
              )}
            </td>
            
            {allKeys.map((key) => (
              <td key={key}>
                {key === 'Status' ? (
                  <StatusBox status={row[key]} />
                ) : key === 'Date' ? (
                  formatDateToMorocco(row[key])
                ) : key === 'message' ? (
                  <span dangerouslySetInnerHTML={{ __html: renderMessageHTML(row[key] || '') }} />
                ) : key === 'source' ? (
                  renderSourceIcon(row[key])
                ) : (
                  row[key] !== undefined ? row[key] : '-'
                )}
              </td>
            ))}
            {enableActions && (
              <td>
                <button
                  style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                  onClick={(e) => { e.stopPropagation(); onEdit(row.id); }}
                >
                  <AiOutlineEdit size={20} color="blue" />
                </button>
                <button
                  style={{ background: 'transparent', border: 'none', cursor: 'pointer', marginLeft: '15px' }}
                  onClick={(e) => { e.stopPropagation(); onDelete(row.id); }}
                >
                  <AiOutlineDelete size={20} color="red" />
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};



export default DynamicTableLead;
