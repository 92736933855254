import React, { useState, useEffect } from "react";
import { MdAdd } from "react-icons/md";
import {
  Button,
  Modal,
  TextField,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CampaignsPage.css";
import DynamicTable from "../Components/DynamicTable";
import axios from "../Services/axios"; // Import axios
import API_URL from "../config/config";

const EmailTemplatesPage = () => {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false); // State to track edit mode
  const [formName, setFormName] = useState("");
  const [objectEmail, setObjectEmail] = useState("");
  const [templateId, setTemplateId] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchEmailTemplates = async () => {
      try {
        const response = await axios.get(`${API_URL}/email-templates`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching email templates:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmailTemplates();
  }, []);

  const handleOpen = () => {
    setEditMode(false);
    setOpen(true);
    setFormName("");
    setObjectEmail("");
    setMessage("");
  };

  const handleClose = () => setOpen(false);

  const handleEditOpen = async (templateId) => {
    try {
      const response = await axios.get(
        `${API_URL}/email-templates/${templateId}`
      );
      setFormName(response.data.name);
      setObjectEmail(response.data.object_email);
      setMessage(response.data.message);
      setTemplateId(templateId); // Store the template ID for editing
      setEditMode(true);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching email template data:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name: formName,
      object_email: objectEmail,
      message: message,
    };

    try {
      if (editMode) {
        // Assuming you have access to the templateId from the state or props
        // You need to set this when opening the edit modal
        await axios.put(`${API_URL}/email-templates/${templateId}`, payload);
        console.log("Template updated:", payload);
      } else {
        await axios.post(`${API_URL}/email-templates`, payload);
        console.log("Template created:", payload);
      }

      // Optionally, you could re-fetch the templates here to update the list
      const response = await axios.get(`${API_URL}/email-templates`);
      setData(response.data);

      handleClose();
    } catch (error) {
      console.error("Error submitting template:", error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Gestion des Modèles d'Email
      </Typography>
      <Button
        variant="contained"
        sx={{
          background:
            "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
        }}
        onClick={handleOpen}
      >
        <MdAdd style={{ marginRight: "8px" }} />
        Ajouter un Nouveau Modèle d'Email
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <h2>
            {editMode ? "Modifier le Modèle d'Email" : "Nouveau Modèle d'Email"}
          </h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Nom"
              required
              fullWidth
              margin="normal"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              sx={inputStyle}
            />
            <TextField
              label="Objet de l'Email"
              required
              fullWidth
              margin="normal"
              value={objectEmail}
              onChange={(e) => setObjectEmail(e.target.value)}
              sx={inputStyle}
            />
            <ReactQuill
              theme="snow"
              value={message}
              onChange={setMessage}
              style={{ height: "200px", margin: "10px 0" }}
            />

<div 
      style={{
        marginTop: "50px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        width: '100%',          // Fixed width
        height: '300px',         // Fixed height
        overflowX: 'auto',       // Horizontal scrolling when needed
        overflowY: 'auto',       // Vertical scrolling when needed
      }}
    >
      
      <p>Utilisez les variables suivantes dans votre template pour personnaliser l'email en fonction des informations du destinataire :</p>
      
      <ul>
        <li>
          <strong>{`{{ full_name }}`}</strong> : Nom complet
          
        </li>
        <li>
          <strong>{`{{ city }}`}</strong> : Ville
         
        </li>
        <li>
          <strong>{`{{ phone }}`}</strong> : Numéro de téléphone
          
        </li>
        <li>
          <strong>{`{{ last_name }}`}</strong> : Nom de famille
          
        </li>
        <li>
          <strong>{`{{ first_name }}`}</strong> : Prénom
          
        </li>
        <li>
          <strong>{`{{ confirmation_link }}`}</strong> : Lien de confirmation
          
        </li>
      </ul>
      
  
    </div>

            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                }}
                type="submit"
              >
                Enregistrer
              </Button>
            </div>
          </form>
        </Box>
      </Modal>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "80px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <DynamicTable
          data={data}
          enableCheckboxes={false}
          enableActions={true}
          onEdit={handleEditOpen} // Lien vers la nouvelle fonction handleEditOpen
          onDelete={() => {}} // Passer la fonction handleDelete
        />
      )}
    </Box>
  );
};

// Modal styles using MUI's sx prop
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

// Custom input styles for reduced height and centered placeholders
const inputStyle = {
  "& .MuiInputBase-input": {
    height: "30px",
    padding: "8px",
  },
  "& .MuiInputLabel-root": {
    lineHeight: "1",
  },
};

export default EmailTemplatesPage;
