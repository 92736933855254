import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Divider,
} from "@mui/material";
import axios from "../Services/axios";
import API_URL from "../config/config";

const GeneralSettings = () => {
  const [accountInfo, setAccountInfo] = useState({
    name: "",
    address: "", // Address will be conditionally used
    email: "",
  });

  const [emailConfig, setEmailConfig] = useState({
    smtpServer: "",
    port: "",
    username: "",
    password: "",
    encryption: false,
  });

  const [facebookToken, setFacebookToken] = useState(""); // State for Facebook token
  const [userType, setUserType] = useState(null);

  const [isEmailConfigCreated, setIsEmailConfigCreated] = useState(false);

  useEffect(() => {
    const fetchEmailConfig = async () => {
      try {
        const response = await axios.get(`${API_URL}/email-configuration`);
        if (response.data.data) {
          setEmailConfig({
            smtpServer: response.data.data.smtp_server,
            port: response.data.data.port,
            username: response.data.data.username,
            password: response.data.data.password,
            encryption: Boolean(response.data.data.encryption),
          });
          setIsEmailConfigCreated(true);
        } else {
          setIsEmailConfigCreated(false);
        }
      } catch (error) {
        console.error("Error fetching email configuration:", error);
      }
    };

    const fetchFacebookToken = async () => {
      try {
        const response = await axios.get(`${API_URL}/facebook-configuration`);
        if (response) {
          setFacebookToken(response.data.access_token); // Set the token to state
        }
      } catch (error) {
        console.error("Error fetching Facebook token:", error);
      }
    };

    const fetchUserRole = async () => {
      try {
        const response = await axios.get(`${API_URL}/account/getMyRole`);
        const roleType = response.data.type;
        setUserType(roleType);
        fetchAccountInfo(roleType);
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    const fetchAccountInfo = async (type) => {
      try {
        const response = await axios.get(`${API_URL}/account/getInfos`);
        setAccountInfo({
          name: response.data.name,
          email: response.data.email,
          ...(type === "company" && { address: response.data.address }),
        });
      } catch (error) {
        console.error("Error fetching account info:", error);
      }
    };

    fetchUserRole();
    fetchEmailConfig();
    fetchFacebookToken(); // Fetch the Facebook token when the component mounts
  }, []);

  const handleAccountInfoChange = (e) => {
    const { name, value } = e.target;
    setAccountInfo((prev) => ({ ...prev, [name]: value }));
  };

  const saveAccountInfo = async () => {
    try {
      const payload =
        userType === "company"
          ? {
              name: accountInfo.name,
              address: accountInfo.address,
            }
          : {
              name: accountInfo.name,
            };

      const response = await axios.patch(
        `${API_URL}/account/updateInfos`,
        payload
      );
      console.log("Account info saved successfully", response.data);
    } catch (error) {
      console.error("Error saving account info:", error);
    }
  };

  const handleEmailConfigChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEmailConfig((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFacebookTokenChange = (e) => {
    setFacebookToken(e.target.value); // Handle Facebook token change
  };

  const saveEmailConfig = async () => {
    try {
      const response = await axios.put(`${API_URL}/email-configuration`, {
        smtp_server: emailConfig.smtpServer,
        port: emailConfig.port,
        username: emailConfig.username,
        password: emailConfig.password,
        encryption: emailConfig.encryption,
      });

      console.log("Email configuration saved successfully", response.data);
    } catch (error) {
      console.error("Error saving email configuration:", error);
    }
  };

  const saveFacebookToken = async () => {
    try {
      const response = await axios.post(`${API_URL}/facebook-configuration`, {
        access_token: facebookToken, // Send the token in the specified format
      });

      console.log("Facebook token saved successfully", response.data);
    } catch (error) {
      console.error("Error saving Facebook token:", error);
    }
  };

  const addEmailConfig = () => {
    setEmailConfig({
      smtpServer: "",
      port: "",
      username: "",
      password: "",
      encryption: false,
    });
    setIsEmailConfigCreated(false);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Paramètres Généraux
      </Typography>

      <Paper elevation={3} sx={{ padding: 6, marginBottom: 4 }}>
        <Typography variant="h5">Gestion des Informations du Compte</Typography>
        <Divider sx={{ marginBottom: 3, marginTop: 1 }} />
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            fullWidth
            label="Nom"
            name="name"
            value={accountInfo.name}
            onChange={handleAccountInfoChange}
          />
          {userType === "company" && (
            <TextField
              fullWidth
              label="Adresse"
              name="address"
              value={accountInfo.address}
              onChange={handleAccountInfoChange}
            />
          )}
          <TextField
            fullWidth
            label="Email"
            value={accountInfo.email}
            InputProps={{ readOnly: true }}
          />
        </Box>
        <div style={{ marginTop: "10px", textAlign: "right" }}>
          <Button
            variant="contained"
            onClick={saveAccountInfo}
            sx={{ marginTop: 2 }}
          >
            Enregistrer les Informations du Compte
          </Button>
        </div>
      </Paper>

      <Paper elevation={3} sx={{ padding: 6, marginBottom: 4 }}>
        <Typography variant="h5">Gestion de la Configuration Email</Typography>
        <Divider sx={{ marginBottom: 3, marginTop: 1 }} />
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            fullWidth
            label="Serveur SMTP"
            name="smtpServer"
            value={emailConfig.smtpServer}
            onChange={handleEmailConfigChange}
          />
          <TextField
            fullWidth
            label="Port"
            type="number"
            name="port"
            value={emailConfig.port}
            onChange={handleEmailConfigChange}
          />
          <TextField
            fullWidth
            label="Nom d'utilisateur"
            name="username"
            value={emailConfig.username}
            onChange={handleEmailConfigChange}
          />
          <TextField
            fullWidth
            label="Mot de passe"
            type="password"
            name="password"
            value={emailConfig.password}
            onChange={handleEmailConfigChange}
          />
          <Box display="flex" alignItems="center">
            <input
              type="checkbox"
              name="encryption"
              checked={emailConfig.encryption}
              onChange={handleEmailConfigChange}
            />
            <Typography variant="body1" sx={{ marginLeft: 1 }}>
              Activer le Chiffrement
            </Typography>
          </Box>
        </Box>
        <div style={{ marginTop: "10px", textAlign: "right" }}>
          <Button
            variant="contained"
            onClick={saveEmailConfig}
            sx={{ marginTop: 2 }}
          >
            Enregistrer la Configuration Email
          </Button>
        </div>
      </Paper>

      <Paper elevation={3} sx={{ padding: 6 }}>
        <Typography variant="h5">Gestion des Tokens Facebook</Typography>
        <Divider sx={{ marginBottom: 3, marginTop: 1 }} />
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            fullWidth
            label="Token Facebook"
            value={facebookToken}
            onChange={handleFacebookTokenChange}
          />
        </Box>
        <div style={{ marginTop: "10px", textAlign: "right" }}>
          <Button
            variant="contained"
            onClick={saveFacebookToken}
            sx={{ marginTop: 2 }}
          >
            Enregistrer le Token Facebook
          </Button>
        </div>
      </Paper>
    </Box>
  );
};

export default GeneralSettings;
