import React, { useEffect, useState } from "react";

import StatisticsCardV2 from "../Components/Dashboard Components/StatisticsCardV2";
import StatisticsCardV3 from "../Components/Dashboard Components/StatisticsCardV3";
import PieChartCard from "../Components/Dashboard Components/PieChartCard";
import AreaLineChart from "../Components/Dashboard Components/AreaLineChart";
import CampaignTable from "../Components/Dashboard Components/CampaignTable";
import { Campaign, Group, CalendarToday } from "@mui/icons-material";
import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import axios from "../Services/axios";
import API_URL from "../config/config";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import DownloadIcon from "@mui/icons-material/Download";
const Dashboard = () => {
  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ["#2f227c", "#67bfff", "#8470ff"],
        hoverBackgroundColor: ["#5b4b94", "#7a62b2", "#751cbf"],
      },
    ],
  });

  const [secondLineData, setSecondLineData] = useState({
    labels: [],
    datasets: [
      {
        label: "Leads by Source (Facebook)",
        data: [],
        fill: true,
        backgroundColor: "#389ddd",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
      },
      {
        label: "Leads by Source (Web)",
        data: [],
        fill: true,
        backgroundColor: "white",
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  });

  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [totalCampaignsGrowth, setTotalCampaignsGrowth] = useState(0);
  const [leadsGenerated, setLeadsGenerated] = useState(0);
  const [leadsGeneratedGrowth, setLeadsGeneratedGrowth] = useState(0);
  const [activeCampaigns, setActiveCampaigns] = useState(0);
  const [activeCampaignsGrowth, setActiveCampaignsGrowth] = useState(0);
  const [inactiveCampaigns, setInactiveCampaigns] = useState(0);
  const [inactiveCampaignsGrowth, setInactiveCampaignsGrowth] = useState(0);
  const [LeadsGeneratedToday, setLeadsGeneratedToday] = useState(0);
  const [LeadsGeneratedTodayGrowth, setLeadsGeneratedTodayGrowth] = useState(0);
  const [LeadsTodayReminded, setLeadsTodayReminded] = useState(0);
  const [LeadsFutureReminded, setLeadsFutureReminded] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [exportFormat, setExportFormat] = useState("");

  const fetchPieData = async () => {
    try {
      const response = await axios.get(`${API_URL}/kpi/countLeadsBySource`);
      const leadCounts = response.data.lead_counts;

      const labels = Object.keys(leadCounts);
      const data = Object.values(leadCounts);

      setPieData({
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: ["#38b2fa", "#134992", "#c8cbcd"],
            hoverBackgroundColor: ["#5b4b94", "#7a62b2", "#751cbf"],
          },
        ],
      });

      console.log(data);
    } catch (error) {
      console.error("Error fetching pie chart data", error);
    }
  };

  const fetchSecondLineData = async () => {
    try {
      const response = await axios.get(`${API_URL}/kpi/getLeadsChartDataV2`);
      const chartData = response.data.chart_data;

      const webData = Object.values(chartData.Web).reverse(); // Data for Web source
      const facebookData = Object.values(chartData.Facebook).reverse(); // Data for Facebook source
      const dates = Object.keys(chartData.Web).reverse(); // Use dates from the Web source

      setSecondLineData({
        labels: dates,
        datasets: [
          {
            label: "Leads by Source (Facebook)",
            data: facebookData,
          },
          {
            label: "Leads by Source (Web)",
            data: webData,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching second line chart data", error);
    }
  };

  const fetchTotalCampaigns = async () => {
    try {
      const response = await axios.get(`${API_URL}/kpi/getCampaignkpi`);
      setTotalCampaigns(response.data.total_campaigns);
      setTotalCampaignsGrowth(response.data.daily_percentile);
    } catch (error) {
      console.error("Error fetching total campaigns", error);
    }
  };
  const fetchLeadsRemindTodayStatistics = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/kpi/leads/count-today-remind`
      );
      setLeadsTodayReminded(response.data.count);
    } catch (error) {
      console.error("Error fetching total campaigns", error);
    }
  };
  const fetchLeadsRemindFutureStatistics = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/kpi/leads/count-future-remind`
      );
      setLeadsFutureReminded(response.data.count);
    } catch (error) {
      console.error("Error fetching total campaigns", error);
    }
  };

  const fetchLeadsGenerated = async () => {
    try {
      const response = await axios.get(`${API_URL}/kpi/getLeadskpi`);
      setLeadsGenerated(response.data.total_leads);
      setLeadsGeneratedGrowth(response.data.daily_percentile);
    } catch (error) {
      console.error("Error fetching leads generated", error);
    }
  };

  const fetchActiveCampaigns = async () => {
    try {
      const response = await axios.get(`${API_URL}/kpi/getActiveCampaignskpi`);
      setActiveCampaigns(response.data.total_active_campaigns);
      setActiveCampaignsGrowth(response.data.daily_percentile);
    } catch (error) {
      console.error("Error fetching active campaigns", error);
    }
  };

  const fetchTodayLeadsStatistics = async () => {
    try {
      const response = await axios.get(`${API_URL}/kpi/leads/count-today`);
      setLeadsGeneratedToday(response.data.today_leads_count);
      setLeadsGeneratedTodayGrowth(response.data.today_leads_percentile);
    } catch (error) {
      console.error("Error fetching active campaigns", error);
    }
  };

  const fetchInactiveCampaigns = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/kpi/getLeadsStatisticsByStatus`
      );
      setInactiveCampaigns(response.data.total_leads);
      setInactiveCampaignsGrowth(response.data.today_leads_percentile);
    } catch (error) {
      console.error("Error fetching inactive campaigns", error);
    }
  };

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };

  const handleExportPDF = async () => {
    const doc = new jsPDF("p", "mm", "a4"); // Set PDF orientation and size (A4 is typical for reports)
    const content = document.getElementById("dashboardContent");

    // Wait until html2canvas finishes rendering the content
    await html2canvas(content, {
      scrollX: 0, // No scrolling in X
      scrollY: -window.scrollY, // Adjust Y scroll to ensure visibility
      width: content.scrollWidth, // Set canvas width to full content width
      height: content.scrollHeight, // Set canvas height to full content height
      x: 0, // Don't shift the content in X axis
      y: 0, // Don't shift the content in Y axis
      useCORS: true, // Make sure to use CORS for cross-origin images
      scale: 2, // Scale the canvas for higher resolution (optional, you can adjust it)
    }).then((canvas) => {
      // Convert the canvas to an image (base64-encoded)
      const imgData = canvas.toDataURL("image/png");

      // Get the page size (A4) and calculate how much of the content fits on the first page
      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;

      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the first page with the image
      doc.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      // Check if the content exceeds the page height and add more pages if needed
      if (imgHeight > pageHeight) {
        let yOffset = 0;
        while (yOffset < imgHeight) {
          // Add a new page
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, -yOffset, imgWidth, imgHeight);
          yOffset += pageHeight;
        }
      }

      // Save the PDF
      doc.save("dashboard.pdf");
    });
  };

  const handleExportJPEG = async () => {
    const content = document.getElementById("dashboardContent");

    await html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "dashboard.jpg";
      link.click();
    });
  };

  const handleExportSelection = (format) => {
    setExportFormat(format);
    handleCloseExportMenu();
    if (format === "PDF") {
      handleExportPDF();
    } else if (format === "JPEG") {
      handleExportJPEG();
    }
  };

  const getUserInfo = async () => {
    try {
      const response = await axios.get(`${API_URL}/account/getInfos`);
      setUserInfo(response.data); // Set the fetched data in the state
    } catch (err) {
      setError(err.message); // Handle errors
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchTotalCampaigns(),
        fetchLeadsGenerated(),
        fetchActiveCampaigns(),
        fetchInactiveCampaigns(),
        fetchTodayLeadsStatistics(),
        fetchLeadsRemindTodayStatistics(),
        fetchLeadsRemindFutureStatistics(),
        getUserInfo(),
      ]);
    };

    fetchData();
    fetchPieData();
    fetchSecondLineData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "20px",
        boxSizing: "border-box",
        marginBottom: "40px", // Adjust the margin bottom of the dashboard here
      }}
    >
      
      <Typography
        variant="h4"
        style={{ margin: "35px 0 15px 0", width: "100%" }}
      >
        Tableau de Bord
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end", // Align button to the right
          width: "100%",
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        {/* Add Export Button aligned to the right of the dashboard */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportClick}
          sx={{
            mb: "20px", // Add marginBottom to the button for spacing
            display: "flex", // Ensure button aligns properly
            alignItems: "center", // Align text and icon
          }}
          startIcon={<DownloadIcon />} // Add the download icon
        >
          Export
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseExportMenu}
        >
          <MenuItem onClick={() => handleExportSelection("PDF")}>
            Export as PDF
          </MenuItem>
          <MenuItem onClick={() => handleExportSelection("JPEG")}>
            Export as JPEG
          </MenuItem>
        </Menu>
      </Box>

      {/* Main Dashboard Content */}
      <div id="dashboardContent" style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
            padding: 2,
            boxSizing: "border-box",
          }}
        >
          {/* Statistics Cards */}
          <StatisticsCardV2
            title="Aperçu des campagnes"
            name1="Total des Campagnes"
            value1={totalCampaigns}
            percentile1={totalCampaignsGrowth}
            name2="Campagnes Actives"
            value2={activeCampaigns}
            percentile2={activeCampaignsGrowth}
            icon={<Campaign />}
            bgColorCircle="rgb(67, 160, 71)"
          />
          <StatisticsCardV3
            title="Aperçu des prospects"
            name1="Total Leads"
            value1={leadsGenerated}
            percentile1={leadsGeneratedGrowth}
            name2="Leads Aujourd'hui"
            value2={LeadsGeneratedToday}
            percentile2={LeadsGeneratedTodayGrowth}
            name3="Leads Traités"
            value3={inactiveCampaigns}
            percentile3={inactiveCampaignsGrowth}
            icon={<Group />}
            bgColorCircle="rgb(26, 115, 232)"
          />
          <StatisticsCardV2
            title="Statistiques des Rappels"
            name1="Nombre de rappels aujourd'hui"
            value1={LeadsTodayReminded}
            percentile1={0}
            name2="Total des rappels"
            value2={LeadsFutureReminded}
            percentile2={0}
            icon={<CalendarToday />}
            bgColorCircle="#f76015"
          />
        </Box>

        {/* Charts */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
            padding: 2,
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{ display: { xs: "block", md: "flex" }, width: "100%", gap: 2 }}
          >
            <Box
              sx={{
                flex: 1,
                maxWidth: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "35%",
                  xl: "35%",
                },
                mb: { xs: 2, md: 0 },
              }}
            >
              <PieChartCard title="Distribution des Campagnes" data={pieData} />
            </Box>
            <Box
              sx={{
                flex: 1,
                maxWidth: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "62%",
                  xl: "70%",
                },
              }}
            >
              <AreaLineChart title="Leads by Source" data={secondLineData} />
            </Box>
          </Box>
        </Box>

        {/* Campaign Table */}
        <div style={{ minWidth: "100%", marginTop: "20px" }}>
          <CampaignTable />
        </div>
      </div>
    </div>
  );
};


export default Dashboard;


const greetingStyle = {
  fontSize: '24px',
  color: '#2C3E50', // Dark greyish-blue for a professional tone
  textAlign: 'center',
  marginTop: '20px',
  fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif', // Clean sans-serif font
};

const nameStyle = {
  fontWeight: '600', // Semi-bold for better visibility but not too strong
  color: '#34495E', // Dark grey, a bit lighter than the greeting color for balance
  fontSize: '30px',
};
