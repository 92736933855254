import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';
import axios from '../../Services/axios'; // Ensure you have axios configured correctly
import API_URL from '../../config/config';

const CampaignTable = () => {
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(`${API_URL}/kpi/getLastFiveCampaigns`);
      setCampaigns(response.data);
    } catch (error) {
      console.error("Error fetching campaigns", error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <Box
      sx={{
        maxWidth: 'auto',
        margin: 2,
        padding: 3,
        borderRadius: 4,
        backgroundColor: '#353a50',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' ,color:'white'}}>
        <Typography variant="h6" sx={{ flexGrow: 1 ,color:'white'}}>
          Top Campaigns
        </Typography>
        <InfoIcon sx={{ color: 'white', marginLeft: 1 }} />
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <Box sx={{ marginTop: 2 }}>
        <Table size="small" sx={{ borderRadius: 4, overflow: 'hidden', width: '100%' ,color:'white'}}>
          <TableHead>
            <TableRow sx={{  background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',color:'white' }}>
              <TableCell sx={{ padding: '16px',color:'white',fontWeight:'bold'  }}>ID</TableCell>
              <TableCell sx={{ padding: '16px',color:'white',fontWeight:'bold'   }}>Name</TableCell>
              <TableCell sx={{ padding: '16px',color:'white',fontWeight:'bold'  }}>Description</TableCell>
              <TableCell sx={{ padding: '16px',color:'white',fontWeight:'bold'  }}>Status</TableCell>
              <TableCell sx={{ padding: '16px',color:'white',fontWeight:'bold'  }}>Start Date</TableCell>
              <TableCell sx={{ padding: '16px',color:'white',fontWeight:'bold'  }}>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign) => (
              <TableRow
                key={campaign.id}
                sx={{
                  '&:hover': { backgroundColor: '#4b5175' },
                  color:'white'
                }}
              >
                <TableCell sx={{ padding: '16px' ,
                  color:'white'}}>{campaign.id}</TableCell>
                <TableCell sx={{ padding: '16px' ,
                  color:'white'}}>{campaign.name}</TableCell>
                <TableCell sx={{ padding: '16px' ,
                  color:'white'}}>{campaign.description}</TableCell>
                <TableCell sx={{ padding: '16px' ,
                  color:'white'}}>
                  <span style={{ color: campaign.status === 'active' ? 'green' : 'red' }}>
                    {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)} {/* Capitalize status */}
                  </span>
                </TableCell>
                <TableCell sx={{ padding: '16px',
                  color:'white' }}>{campaign.start_date}</TableCell>
                <TableCell sx={{ padding: '16px' ,
                  color:'white'}}>{campaign.end_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default CampaignTable;
