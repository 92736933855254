import React, { useState } from "react";
import { MdAdd } from "react-icons/md"; // Icon for add button
import {
  Button,
  Modal,
  TextField,
  Box,
} from "@mui/material";
import BaseTableComponent from "../Components/BaseTableComponent";
import "./CampaignsPage.css"; // Custom styles
import ReactQuill from "react-quill"; // Import ReactQuill
import "react-quill/dist/quill.snow.css";

const SMSTemplatesPage = () => {
  const [open, setOpen] = useState(false); // State to manage modal visibility
  const [SMSName, setSMSName] = useState("");
  const [senderName, setSenderName] = useState("");
  const [message, setMessage] = useState(""); // Added state for message

  const data = [
    { id: 1, col0: "Data 1", col1: "Example 1 with some long text" },
    { id: 2, col0: "Data 2", col1: "Example 2" },
    { id: 3, col0: "Data 3", col1: "Example 3" },
    { id: 4, col0: "Data 4", col1: "Example 4 with more text" },
    {
      id: 5,
      col0: "Data 5",
      col1: "Example 5 with a lot of content that may wrap into multiple lines",
    },
    { id: 6, col0: "Data 6", col1: "Example 6" },
    { id: 7, col0: "Data 7", col1: "Example 7" },
  ];

  // Open modal
  const handleOpen = () => setOpen(true);

  // Close modal
  const handleClose = () => setOpen(false);

  // Form submit handler
  const handleFormSubmit = (e) => {
    e.preventDefault();
    
    console.log("Submitted: ", {
      SMSName,
      senderName,
      message,
    });

    handleClose(); // Close the modal after submission
  };

  return (
    <div>
      <h1>SMS Templates Management</h1>
      <button className="add-campaign-button" onClick={handleOpen}>
        <MdAdd style={{ marginRight: "8px" }} />
        Add SMS Template
      </button>

      {/* Modal for creating a new SMS template */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <h2>Create New SMS Template</h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Template Name"
              required
              fullWidth
              margin="normal"
              value={SMSName}
              onChange={(e) => setSMSName(e.target.value)}
              sx={inputStyle} // Apply reduced height
            />
            <TextField
              label="Sender Name"
              required
              fullWidth
              margin="normal"
              value={senderName}
              onChange={(e) => setSenderName(e.target.value)}
              sx={inputStyle} 
            />
            <TextField
              label="Message"
              required
              multiline
              fullWidth
              margin="normal"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={inputStyle} 
            />
            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Box>
      </Modal>

      <BaseTableComponent
        data={data}
        enableCheckboxes={true}
        enableActions={true}
      />
    </div>
  );
};

// Modal styles using MUI's sx prop
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

// Custom input styles for reduced height and centered placeholders
const inputStyle = {
  "& .MuiInputBase-input": {
    height: "30px", // Reduce input height
    padding: "8px", // Ensure padding is applied for text
  },
  "& .MuiInputLabel-root": {
    lineHeight: "1", // Ensure label is aligned properly
  },
};

export default SMSTemplatesPage;
