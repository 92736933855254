import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Modal,
  TextField,
  Button,
  Switch,
  MenuItem,
  IconButton,
  Select, // Import Select
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import axios from "../Services/axios";
import DynamicTableLead from "../Components/DynamicTableLead";
import ReactQuill from "react-quill";
import ExportIcon from "@mui/icons-material/SaveAlt";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as FormIcon } from "../Icons/FormIcon.svg";
import API_URL from "../config/config";
import { ReactComponent as CampaignIcon } from "../Icons/CampaignIcon.svg";
import { ReactComponent as LeadIcon } from "../Icons/LeadIcon.svg";

const LeadsPage = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [openCampaigns, setOpenCampaigns] = useState({});
  const [selectedForm, setSelectedForm] = useState(null);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [message, setMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [loadingForms, setLoadingForms] = useState({});
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [loadingLeads, setLoadingLeads] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [isSelectAll, setIsSelectAll] = useState(false);

  // Pagination state
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    total: 0,
    perPage: 2,
  });
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    const fetchAllCampaigns = async () => {
      setLoadingCampaigns(true);
      try {
        const token = localStorage.getItem("token");
        let allCampaigns = [];
        let currentPage = 1;
        let lastPage = 1;

        do {
          const response = await axios.get(
            `${API_URL}/campaigns?page=${currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          allCampaigns = [...allCampaigns, ...response.data.data];
          currentPage = response.data.meta.current_page + 1;
          lastPage = response.data.meta.last_page;
        } while (currentPage <= lastPage);

        setCampaigns(allCampaigns);

        // Set openCampaigns state to open all campaigns
        const initialOpenState = {};
        allCampaigns.forEach((campaign) => {
          initialOpenState[campaign.id] = true; // Open all campaigns by default
        });
        setOpenCampaigns(initialOpenState);

        // Fetch forms for all campaigns
        const formFetchPromises = allCampaigns.map((campaign) =>
          fetchForms(campaign.id)
        );
        const formsArray = await Promise.all(formFetchPromises);

        // Update campaigns with their respective forms
        const updatedCampaigns = allCampaigns.map((campaign, index) => ({
          ...campaign,
          forms: formsArray[index],
        }));

        setCampaigns(updatedCampaigns);
      } catch (error) {
        console.error("Error fetching campaigns: ", error);
      } finally {
        setLoadingCampaigns(false);
      }
    };

    fetchAllCampaigns();
  }, []);

  const fetchForms = async (campaignId) => {
    try {
      const response = await axios.get(
        `${API_URL}/forms/campaigns/${campaignId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching forms:", error);
      return [];
    }
  };

  const fetchLeads = async (formId, page = 1) => {
    setLoadingPage(true);
    try {
      const response = await axios.get(
        `${API_URL}/leads/form/${formId}?page=${page}&source=${selectedSource}&status=${selectedStatus}&search=${searchTerm}` // Include status in the request
      );
      setLeads(response.data.data);
      setPagination({
        currentPage: response.data.meta.current_page,
        lastPage: response.data.meta.last_page,
        total: response.data.meta.total,
        perPage: response.data.meta.per_page,
      });
    } catch (error) {
      console.error("Error fetching leads:", error);
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchLeadDetails = async (leadId) => {
    try {
      const response = await axios.get(`${API_URL}/leads/${leadId}`);
      setSelectedLead(response.data);
      setMessage(response.data.note || "");
    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  const handleRowClick = (row) => {
    fetchLeadDetails(row.id);
    setModalOpen(true);
  };

  useEffect(() => {
    if (selectedForm) {
      fetchLeads(selectedForm.id, pagination.currentPage);
    }
  }, [selectedForm, searchTerm, selectedSource, selectedStatus]);

  const handleCampaignClick = async (campaignId) => {
    setOpenCampaigns((prev) => ({
      ...prev,
      [campaignId]: !prev[campaignId],
    }));

    if (!openCampaigns[campaignId]) {
      setLoadingForms((prev) => ({ ...prev, [campaignId]: true })); // Start loading forms
      const forms = await fetchForms(campaignId);
      setLoadingForms((prev) => ({ ...prev, [campaignId]: false })); // Stop loading forms
      setCampaigns((prevCampaigns) =>
        prevCampaigns.map((campaign) =>
          campaign.id === campaignId ? { ...campaign, forms } : campaign
        )
      );
    }
  };

  const handleExport = async () => {
    if (!selectedForm) {
      alert("Please select a form to export leads.");
      return;
    }

    try {
      const response = await axios.get(
        `${API_URL}/forms/${selectedForm.id}/leads/export`
      );
      const leadsData = response.data.data;

      // Convert leadsData to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(leadsData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

      // Generate Excel file and trigger download
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "leads_export.xlsx");
    } catch (error) {
      console.error("Error exporting leads:", error);
    }
  };


  const handleExportV2 = async (formId) =>{
    if (!selectedForm) {
      alert("Please select a form to export leads.");
      return;
    }

    
    try {
      const response = await axios.get(
        `${API_URL}/forms/${formId}/leads/export?source=${selectedSource}&status=${selectedStatus}&search=${searchTerm}` // Include status in the request
      );
      const leadsData = response.data.data;

      // Convert leadsData to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(leadsData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

      // Generate Excel file and trigger download
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, "leads_export.xlsx");
    } catch (error) {
      console.error("Error exporting leads:", error);
    }
  };

  const handleCloseModal = async () => {
    if (selectedLead) {
      const leadId = selectedLead.id; // Get the lead ID
      const updatedLeadData = {
        reminded: selectedLead.reminded, // Convert to boolean
        remind_date: selectedLead.remind_date, // Use the datetime value
        note: message, // Use the current message
        status: selectedLead.status, // Current status
      };

      console.log(selectedLead.id);
      console.log(updatedLeadData);

      try {
        // Send the update request
        await axios.put(`${API_URL}/leads/${leadId}`, updatedLeadData);
        console.log("Lead updated successfully");
      } catch (error) {
        console.error("Error updating lead:", error);
      }
    }

    // Close the modal and reset state
    setModalOpen(false);
    setSelectedLead(null);
    setMessage("");
  };

  const handleRemindedChange = (event) => {
    const isChecked = event.target.checked;
    setSelectedLead((prev) => ({
      ...prev,
      reminded: isChecked ? 1 : 0,
      remind_date: isChecked ? prev.remind_date : "",
    }));
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setSelectedLead((prev) => ({ ...prev, status: newStatus }));
  };

  // Pagination handlers
  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchLeads(selectedForm.id, pagination.currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchLeads(selectedForm.id, pagination.currentPage - 1);
    }
  };

  // New function to handle page change from dropdown
  const handlePageChange = (event) => {
    const newPage = event.target.value;
    fetchLeads(selectedForm.id, newPage);
  };

  return (
    <Box sx={{ p: 2.5 }}>
      <Typography variant="h4" gutterBottom>
        Prospects
      </Typography>
      <Box sx={{ display: "flex" }}>




        <Box sx={{ width: "250px", mr: 4 }}>
          <List sx={{ padding: 0, borderRadius: "20px" }}>
            {loadingCampaigns ? (
              <CircularProgress />
            ) : campaigns.length === 0 ? (
              <Typography
                sx={{
                  ml: 4,
                  mt: 1,
                  mb: 1,
                  fontStyle: "italic",
                  color: "text.secondary",
                }}
              >
                Aucune campagne disponible.
              </Typography>
            ) : (
              campaigns.map((campaign) => (
                <Box sx={{ bgcolor: "white" }} key={campaign.id}>
                  <React.Fragment>
                    <ListItem
                      button
                      onClick={() => handleCampaignClick(campaign.id)}
                      
                      sx={{
                        // bgcolor: openCampaigns[campaign.id] ? "black" : "transparent",
                        background:
                          "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                        borderRadius: 1,
                        "&:hover": {
                          bgcolor: "#cfcfcf",
                        },
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <CampaignIcon fill="white" />
                      </ListItemIcon>
                      <ListItemText
                        primary={campaign.name}
                        sx={{ fontWeight: "bold", color: "white" }}
                      />
                      {openCampaigns[campaign.id] ? (
                        <ExpandLess fill="white" />
                      ) : (
                        <ExpandMore fill="white" />
                      )}
                    </ListItem>

                    <Collapse
                      in={openCampaigns[campaign.id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {loadingForms[campaign.id] ? (
                        <CircularProgress size={20} sx={{ ml: 4 }} />
                      ) : (
                        <List component="div" disablePadding>
                          {campaign.forms && campaign.forms.length > 0 ? (
                            campaign.forms.map((form) => (
                              <ListItem
                                button
                                key={form.id}
                                onClick={() => setSelectedForm(form)}
                                sx={{
                                  pl: 4,
                                  bgcolor: "black",
                                  color: "white",
                                  "&:hover": {
                                    bgcolor: "black",
                                    textDecoration: "underline",
                                  },
                                  cursor: "pointer",
                                  transition: "background-color 0.3s",
                                }}
                              >
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                  <LeadIcon fill="white" />
                                </ListItemIcon>
                                <ListItemText primary={form.name} />
                              </ListItem>
                            ))
                          ) : (
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 4,
                                mt: 1,
                                mb: 1,
                                fontStyle: "italic",
                                color: "text.secondary",
                              }}
                            >
                              Aucun formulaire existant...
                            </Typography>
                          )}
                        </List>
                      )}
                    </Collapse>
                  </React.Fragment>
                </Box>
              ))
            )}
          </List>
        </Box>



        <Box sx={{ flexGrow: 1 }}>
          {selectedForm && leads.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  mr: 2,
                  height: "40px",
                }}
              >
                <TextField
                  placeholder="Recherche..."
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    fetchLeads(selectedForm.id, 1);
                  }}
                  sx={{ flexGrow: 1, mr: 2,backgroundColor:"white" }}
                />
              </Box>

              <Box sx={{ minWidth: 150, mr: 2 }}>
                <Select
                  value={selectedSource}
                  onChange={(e) => {
                    setSelectedSource(e.target.value);
                    fetchLeads(selectedForm.id, 1);
                  }}
                  displayEmpty
                  fullWidth
                  variant="outlined"
                  sx={{ minWidth: 150, height: "40px", mr: 2,backgroundColor:"white" }}
                >
                  <MenuItem value="">
                    <em>Source</em>
                  </MenuItem>
                  <MenuItem value="web">Web</MenuItem>
                  <MenuItem value="facebook">Facebook</MenuItem>
                </Select>
              </Box>
              <Box sx={{ minWidth: 150, mr: 2 }}>
                <Select
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    fetchLeads(selectedForm.id, 1); // Fetch leads when status changes
                  }}
                  displayEmpty
                  fullWidth
                  variant="outlined"
                  sx={{ minWidth: 150, height: "40px", mr: 2,backgroundColor:"white" }}
                >
                  <MenuItem value="">
                    <em>Status</em>
                  </MenuItem>
                  <MenuItem value="Faux numéro">Faux numéro</MenuItem>
                  <MenuItem value="Injoignable">Injoignable</MenuItem>
                  <MenuItem value="Pas intéressé">Pas intéressé</MenuItem>
                  <MenuItem value="Réponse positive">Réponse positive</MenuItem>
                  <MenuItem value="À rappeler">À rappeler</MenuItem>
                </Select>
              </Box>

              <Button
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                    height: "40px"
                }}
                startIcon={<ExportIcon />}
                onClick={() => handleExportV2(selectedForm.id)}
            
              >
                Exporter
              </Button>
            </Box>
          )}

          {leads.length > 0 && pagination.lastPage > 1 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <IconButton
                onClick={handlePreviousPage}
                disabled={pagination.currentPage === 1 || loadingPage}
              >
                <NavigateBefore />
              </IconButton>

              <Select
                value={pagination.currentPage}
                onChange={handlePageChange}
                sx={{ minWidth: 100, mx: 2 }}
              >
                {Array.from({ length: pagination.lastPage }, (_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>

              <IconButton
                onClick={handleNextPage}
                disabled={
                  pagination.currentPage === pagination.lastPage || loadingPage
                }
              >
                <NavigateNext />
              </IconButton>
            </Box>
          )}

          {loadingPage ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <DynamicTableLead
              data={leads}
              enableCheckboxes={true}
              enableActions={true}
              onEdit={() => {}}
              onDelete={() => {}}
              onRowClick={handleRowClick}
            />
          )}
        </Box>





        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box sx={modalStyle}>
            {selectedLead && (
              <>
                <Typography variant="h6">Détails du Prospect</Typography>
                <Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                    {selectedLead.lead_data &&
                      selectedLead.lead_data.map((field) => (
                        <TextField
                          key={field.id}
                          label={field.field_name}
                          value={field.value}
                          fullWidth
                          margin="normal"
                          InputProps={{ readOnly: true }}
                          sx={{ flexBasis: "45%" }}
                        />
                      ))}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="body1">Rappelé :</Typography>
                    <Switch
                      checked={selectedLead.reminded === 1}
                      onChange={handleRemindedChange}
                    />
                    <TextField
                      label="Date et Heure du Rappel"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      value={
                        selectedLead.reminded === 1
                          ? selectedLead.remind_date
                          : ""
                      }
                      onChange={(e) =>
                        setSelectedLead((prev) => ({
                          ...prev,
                          remind_date: e.target.value,
                        }))
                      }
                      fullWidth
                      margin="normal"
                      InputProps={{ readOnly: selectedLead.reminded !== 1 }}
                      sx={{ marginLeft: 2 }}
                    />
                    <TextField
                      label="Statut"
                      select
                      value={selectedLead.status || ""}
                      onChange={handleStatusChange}
                      fullWidth
                      sx={{ marginLeft: 2 }}
                    >
                      <MenuItem value="Faux numéro">Faux numéro</MenuItem>
                      <MenuItem value="Injoignable">Injoignable</MenuItem>
                      <MenuItem value="Pas intéressé">Pas intéressé</MenuItem>
                      <MenuItem value="Réponse positive">
                        Réponse positive
                      </MenuItem>
                      <MenuItem value="À rappeler">À rappeler</MenuItem>
                    </TextField>
                  </Box>
                  <Box sx={{ mb: 7 }}>
                    <ReactQuill
                      theme="snow"
                      value={message}
                      onChange={setMessage}
                      style={{ height: "200px", margin: "10px 0" }}
                    />
                  </Box>
                </Box>
                <div style={{ marginTop: "10px", textAlign: "right" }}>
                  <Button
                    onClick={handleCloseModal}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, background:
                      "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))", }}
                  >
                    Enregistrer
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

export default LeadsPage;
