import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';

const StatisticsCardV2 = ({
  title,
  name1,
  value1,
  percentile1,
  name2,
  value2,
  percentile2,
  icon,
  bgColorCircle,
}) => {
  const timelineData = [60, 80, 40, 100, 70, 90, 50];

  return (
    <Card
      sx={{
        margin: 1,
        width: { xs: '100%', sm: '100%', md: '31%', lg: '31%', xl: '31%' },
        padding: 3,
        borderRadius: 4,
        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
        backgroundColor: '#353a50',
        
        border: '1px solid #e0e0e0',
        transition: 'transform 0.2s',
        overflow: 'visible',
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <CardContent>
        {/* Container for icon and title */}
        <Box display="flex" flexDirection="column" alignItems="center" marginBottom={3}>
          <Box
            sx={{
              bgcolor: bgColorCircle || '#1976d2',
              borderRadius: '40%',
              padding: 2,
              marginTop: '-80px',
              marginRight:'80%', // Make the icon overlap by using negative margin
              color:'white',
              width: 50,
              height: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 6px 12px rgba(25, 118, 210, 0.3)',
            }}
          >
            {React.cloneElement(icon, { sx: { fontSize: 33 } })}
          </Box>
          <Typography variant="h6" color="text.primary" fontWeight="600" textAlign="center" marginTop={2} sx={{color:'white',}}> 
            {title}
          </Typography>
        </Box>

        {/* Data Blocks in a single row */}
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          {/* Data Block 1 */}
          <Box flexGrow={1} textAlign="center">
            <Typography variant="body2" color="white" fontWeight="500" >
              {name1}
            </Typography>
            <Typography variant="h4" color="white" fontWeight="700" >
              {value1}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              {percentile1 > 0 ? (
                <ArrowDropUp sx={{ color: '#4caf50', fontSize: 24 }} />
              ) : (
                <ArrowDropDown sx={{ color: '#f44336', fontSize: 24 }} />
              )}
              <Typography
                variant="body2"
                sx={{
                  color: percentile1 > 0 ? '#4caf50' : '#f44336',
                  fontWeight: 600,
                  marginLeft: 0.5,
                }}
              >
                {Math.abs(percentile1)}%
              </Typography>
            </Box>
          </Box>

          {/* Data Block 2 */}
          <Box flexGrow={1} textAlign="center">
            <Typography variant="body2" color="white" fontWeight="500">
              {name2}
            </Typography>
            <Typography variant="h4" color="white" fontWeight="700">
              {value2}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              {percentile2 > 0 ? (
                <ArrowDropUp sx={{ color: '#4caf50', fontSize: 24 }} />
              ) : (
                <ArrowDropDown sx={{ color: '#f44336', fontSize: 24 }} />
              )}
              <Typography
                variant="body2"
                sx={{
                  color: percentile2 > 0 ? '#4caf50' : '#f44336',
                  fontWeight: 600,
                  marginLeft: 0.5,
                }}
              >
                {Math.abs(percentile2)}%
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>

      {/* Mock timeline chart in footer */}
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingX: 2,
          paddingBottom: 2,
          backgroundColor: 'black',
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
        }}
      >
        {timelineData.map((height, index) => (
          <Box
            key={index}
            sx={{
              width: 8,
              height: `${height}%`,
              backgroundColor: index === timelineData.length - 1 ? '#1976d2' : '#90caf9',
              borderRadius: 1,
              transition: 'background-color 0.2s',
              '&:hover': {
                backgroundColor: '#1565c',
              },
            }}
          />
        ))}
      </Box> */}
    </Card>
  );
};

export default StatisticsCardV2;
