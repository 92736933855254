import { Routes, Route } from "react-router-dom";
import SignIn from "../Pages/SignIn"; // Capital "P" in 'Pages'
import SignUp from "../Pages/SignUp";
import FormPage from "../Pages/FormPage";
import Layout from '../Components/Layout';
import CampainsPage from '../Pages/CampainsPage'
import EmailTemplatesPage from '../Pages/EmailTemplatesPage'
import SMSTemplatesPage from '../Pages/SMSTemplatesPage'
import GeneratedForm from "../Pages/GeneratedForm";
import UserManagementPage from "../Pages/UserManagementPage";
import GeneralSettings from "../Pages/GeneralSettings"; 
import LeadsPage from "../Pages/LeadsPage";
import PrivateRoute from "./PrivateRoute";
import Sidebar from '../Pages/SideBar';
import Dashboard from '../Pages/Dashboard'
import CalendarPage from "../Pages/Calendar";

const AppRoutes = () => (
  
  <Routes>
    {/* Route publique */}
    <Route path="/" element={<SignIn />} />
    <Route path="/signup" element={<SignUp />} />
    
    {/* Routes protégées */}
    <Route
      path="/Home"
      element={
        <PrivateRoute>
          <Sidebar />
        </PrivateRoute>
      }
    />
    
    <Route
      path="/form"
      element={
        <PrivateRoute>
          <GeneratedForm />
        </PrivateRoute>
      }
    />
    
    <Route element={<PrivateRoute><Layout /></PrivateRoute>}>
      <Route path="/Campains" element={<CampainsPage />} />
      <Route path="/Dashboard" element={<Dashboard />} />
      <Route path="/EmailTemplates" element={<EmailTemplatesPage />} />
      <Route path="/SMSTemplatesPage" element={<SMSTemplatesPage />} />
      <Route path="/FormPage" element={<FormPage />} />
      <Route path="/UserManagementPage" element={<UserManagementPage />} />
      <Route path="/GeneralSettings" element={<GeneralSettings />} />
      <Route path="/Leads" element={<LeadsPage />} />
      <Route path="/Calendar" element={<CalendarPage />} />
    </Route>
  </Routes>
);

export default AppRoutes;