import React, { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Typography,
  Avatar,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Importing dropdown icon
import profileIcon from "../Icons/profileIcon.png";
import axios from "../Services/axios";
import API_URL from "../config/config";

const TopBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState(5); // Example notification count
  const [username, setUsername] = useState("Bonjour , Amine"); // Example username
  const [userInfo, setUserInfo] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // Manage the open/close state of the profile dropdown
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen((prevState) => !prevState); // Toggle dropdown open/close
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false); // Close the dropdown when user clicks outside
  };

  const handleSignOut = () => {
    console.log("Sign out clicked");
    handleProfileMenuClose();
  };

  const handleSettings = () => {
    console.log("Settings clicked");
    handleProfileMenuClose();
  };

  const getUserInfo = async () => {
    try {
      const response = await axios.get(`${API_URL}/account/getInfos`);
      setUserInfo(response.data); // Set the fetched data in the state
      console.log(response.data);
    } catch (err) {
      setError(err.message); // Handle errors
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <AppBar position="static" sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* Notification Section (left of profile box) */}
        <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
          <IconButton color="primary">
            <NotificationsIcon />
            {notifications > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: 18,
                  height: 18,
                  borderRadius: "50%",
                  backgroundColor: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  fontSize: "0.75rem",
                }}
              >
                {notifications}
              </Box>
            )}
          </IconButton>
        </Box>

        {/* Vertical Divider */}
        <Divider orientation="vertical" sx={{ height: 30, margin: "0 16px" }} />

        {/* Profile Box (right) */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Profile Box with username and dropdown indicator */}
          {/* Profile Avatar */}
          <img
            className="profile-img"
            src={profileIcon}
            width="32"
            height="32"
            alt="User"
          />

          {/* Username */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: 2,
              padding: "5px 15px",
              backgroundColor: "#334155",
              color: "white", // Profile box background
              borderRadius: "20px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              position: "relative", // Needed to position the dropdown icon
            }}
            onClick={handleProfileMenuOpen}
          >
            <Typography
              sx={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "1rem",
                fontWeight: 500,
                color: "white",
              }}
            >
              Bonjour ,
              {userInfo ? userInfo.name : "Chargement..."}
            </Typography>

            {/* Dropdown indicator icon (arrow) */}
            <ArrowDropDownIcon
              sx={{
                marginLeft: 1,
                transform: menuOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
                color: "white", // Icon color
              }}
            />
          </Box>

          {/* Profile Dropdown Menu (using ul and li instead of Menu and MenuItem) */}
          {menuOpen && (
            <Box
              sx={{
                position: "absolute",
                top: "45px", // Align dropdown with profile box
                right: 50,
                width: "200px",
                backgroundColor: "#334155",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                zIndex: 1300, // Ensure the dropdown is above the rest of the layout
                padding: "8px 0",
              }}
            >
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                <li
                  style={{
                    padding: "10px 15px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    color: "white", // Text color for the menu items
                    "&:hover": {
                      backgroundColor: "#ddd",
                    },
                  }}
                  onClick={handleSettings}
                >
                  <SettingsIcon sx={{ marginRight: 1, color: "white" }} />{" "}
                  {/* Icon color */}
                  <Typography sx={{ color: "white" }}>Settings</Typography>{" "}
                  {/* Text color */}
                </li>
                <li
                  style={{
                    padding: "10px 15px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    color: "white", // Text color for the menu items
                    "&:hover": {
                      backgroundColor: "#ddd",
                    },
                  }}
                  onClick={handleSignOut}
                >
                  <ExitToAppIcon sx={{ marginRight: 1, color: "white" }} />{" "}
                  {/* Icon color */}
                  <Typography sx={{ color: "white" }}>Sign out</Typography>{" "}
                  {/* Text color */}
                </li>
              </ul>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
