import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import AppTheme from '../shared-theme/AppTheme';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../config/config';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

export default function SignUp(props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = React.useState(false);
  const [passwordConfirmationErrorMessage, setPasswordConfirmationErrorMessage] = React.useState('');
  const [companyError, setCompanyError] = React.useState(false);
  const [companyErrorMessage, setCompanyErrorMessage] = React.useState('');
  const [addressError, setAddressError] = React.useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = React.useState('');

  const navigate = useNavigate(); // For navigation after successful registration

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    if (validateInputs()) {
      const data = new FormData(event.currentTarget);
      const companyData = {
        name: data.get('company'),
        address: data.get('address'),
        email: data.get('email'),
        password: data.get('password'),
        password_confirmation: data.get('passwordConfirmation'),
      };

      try {
        const response = await axios.post(`${API_URL}/register-company`, companyData);
        localStorage.setItem('token', response.data.token); // Store the token
        navigate('/Home'); // Redirect to home after successful registration
      } catch (error) {
        if (error.response) {
          console.error(error.response.data);
          // Handle specific error responses here if needed
        } else {
          console.error('Error:', error.message);
        }
      }
    }
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');
    const passwordConfirmation = document.getElementById('passwordConfirmation');
    const company = document.getElementById('company');
    const address = document.getElementById('address');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!company.value || company.value.length < 1) {
      setCompanyError(true);
      setCompanyErrorMessage('Company name is required.');
      isValid = false;
    } else {
      setCompanyError(false);
      setCompanyErrorMessage('');
    }

    if (!address.value || address.value.length < 1) {
      setAddressError(true);
      setAddressErrorMessage('Address is required.');
      isValid = false;
    } else {
      setAddressError(false);
      setAddressErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (password.value !== passwordConfirmation.value) {
      setPasswordConfirmationError(true);
      setPasswordConfirmationErrorMessage('Passwords do not match.');
      isValid = false;
    } else {
      setPasswordConfirmationError(false);
      setPasswordConfirmationErrorMessage('');
    }

    return isValid;
  };

  return (
    <AppTheme {...props}>
    <CssBaseline enableColorScheme />
    <SignInContainer direction="column" justifyContent="space-between">
      <Card variant="outlined">
        <Typography
          component="h1"
          variant="h4"
          sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
        >
          Inscription
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 2,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="company">Nom de l'entreprise</FormLabel>
            <TextField
              autoComplete="organization"
              name="company"
              required
              fullWidth
              id="company"
              placeholder="Nom de l'entreprise"
              error={companyError}
              helperText={companyErrorMessage}
              color={companyError ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="address">Adresse</FormLabel>
            <TextField
              autoComplete="address"
              name="address"
              required
              fullWidth
              id="address"
              placeholder="Votre adresse"
              error={addressError}
              helperText={addressErrorMessage}
              color={addressError ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
              error={emailError}
              helperText={emailErrorMessage}
              id="email"
              type="email"
              name="email"
              placeholder="votre@email.com"
              autoComplete="email"
              autoFocus
              required
              fullWidth
              variant="outlined"
              color={emailError ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Mot de passe</FormLabel>
            <TextField
              error={passwordError}
              helperText={passwordErrorMessage}
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="current-password"
              required
              fullWidth
              variant="outlined"
              color={passwordError ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="passwordConfirmation">Confirmer le mot de passe</FormLabel>
            <TextField
              error={passwordConfirmationError}
              helperText={passwordConfirmationErrorMessage}
              name="passwordConfirmation"
              placeholder="••••••"
              type="password"
              id="passwordConfirmation"
              required
              fullWidth
              variant="outlined"
              color={passwordConfirmationError ? 'error' : 'primary'}
            />
          </FormControl>
  
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
          >
            S'inscrire
          </Button>
          <Typography sx={{ textAlign: 'center' }}>
            Vous avez déjà un compte ?{' '}
            <span>
              <Link
                component={RouterLink}
                to="/"
                variant="body2"
                sx={{ alignSelf: 'center' }}
              >
                Se connecter
              </Link>
            </span>
          </Typography>
        </Box>
       
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Ajoutez vos boutons de connexion sociale ici */}
        </Box>
      </Card>
    </SignInContainer>
  </AppTheme>
  
  );
}
