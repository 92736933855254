// UserManagementPage.js
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { MdAdd } from "react-icons/md";
import UserTable from "../Components/UserTable";
import axios from "../Services/axios";
import API_URL from "../config/config";

const UserManagementPage = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [role, setRole] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);


  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/role-management/users`);
      setUsers(response.data.users); // Set users from the API response
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs :", error);
      alert("Échec de la récupération des utilisateurs.");
    }
  };

  // Fetch users on initial load
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleDelete = async (userId) => {
    try {
      // Send DELETE request to remove the user from the backend
      const response = await axios.delete(`${API_URL}/role-management/users`, {
        data: { user_id: userId }, // Send user_id in the request body
      });
  
      alert("Êtes-vous sûr de vouloir supprimer l'utilisateur ?");
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
      alert("Échec de la suppression de l'utilisateur.");
    }
  };
  
  const handleTogglePasswordVisibility = (userId) => {
    setUsers(
      users.map((user) =>
        user.id === userId
          ? { ...user, isPasswordVisible: !user.isPasswordVisible }
          : user
      )
    );
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      // Send PATCH request to update the user's role in the backend
      const response = await axios.patch(
        `${API_URL}/role-management/users/role`, // Endpoint to update the role
        {
          user_id: userId, // User ID
          role: newRole,   // New role
        }
      );
  
      setUsers(
        users.map((user) =>
          user.id === userId ? { ...user, role: newRole } : user
        )
      );
      fetchUsers();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rôle :", error);
      alert("Échec de la mise à jour du rôle de l'utilisateur.");
    }
  };
  

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validate if passwords match
    if (password !== passwordConfirmation) {
      alert("Les mots de passe ne correspondent pas");
      return;
    }

    const userData = {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
      role,
    };

    try {
      // Send POST request to create user
      const response = await axios.post(
        `${API_URL}/role-management/create-user`,
        userData
      );

      // Handle successful user creation
      console.log("Utilisateur créé avec succès :", response.data);

      // Assuming the response contains the new user data (e.g., id, name, email, role)
      setUsers((prevUsers) => [
        ...prevUsers,
        {
          id: response.data.id, // Assuming backend returns the new user's ID
          name: response.data.name,
          email: response.data.email,
          role: response.data.role,
        },
      ]);
      fetchUsers();
      handleClose(); // Close the modal after successful creation
    } catch (error) {
      console.error("Erreur lors de la création de l'utilisateur :", error);
      if (error.response) {
        // If the error response exists (e.g., 400 or 500 response from the server)
        alert(
          `Erreur : ${error.response.data.message || "Impossible de créer l'utilisateur"}`
        );
      } else {
        // If there is no response (e.g., network issue)
        alert("Une erreur est survenue lors de la création de l'utilisateur.");
      }
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Gestion des utilisateurs
      </Typography>
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{
          background:
            "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
          ...buttonStyle,
        }}
      >
        <MdAdd style={{ marginRight: "8px" }} />
        Ajouter un utilisateur
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <h2>Créer un nouvel utilisateur</h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Nom"
              required
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="Email"
              required
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Mot de passe"
              type="password"
              required
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="Confirmation du mot de passe"
              type="password"
              required
              fullWidth
              margin="normal"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />

            <FormControl fullWidth margin="normal" required>
              <InputLabel>Rôle</InputLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                label="Rôle"
              >
                <MenuItem value="Manager">Responsable</MenuItem>
                <MenuItem value="Staff">Employé</MenuItem>
              </Select>
            </FormControl>
            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                }}
                type="submit"
              >
                Soumettre
              </Button>
            </div>
          </form>
        </Box>
      </Modal>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 4,
          padding: 2,
        }}
      >
        <UserTable
          users={users}
          onDelete={handleDelete}
          onTogglePasswordVisibility={handleTogglePasswordVisibility}
          onRoleChange={handleRoleChange}
        />
      </Box>
    </Box>
  );
};

// Modal styles
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
const buttonStyle = {
  marginTop: "20px",
};

export default UserManagementPage;
