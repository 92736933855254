import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../Pages/SideBar"; // Adjust the import path as necessary
import { Box } from "@mui/material";
import TopBar from "./TopBar"; // Path to the TopBar component

const Layout = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      
      <Box sx={{ display: "flex", flex: 1 }}>
        <SideBar />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <TopBar />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
