
import DynamicForm from "../Components/DynamicForm";
const formData = {
    name: "Form Name",
    description: "Some Descriptions",
    campaign_id: 2,
    fields: [
      {
        label: "Field Label 1",
        type: "text",
      },
      {
        label: "Field Label 2",
        type: "email",
      },
    ],
  };
  
  const GeneratedForm = () => {
    return (
      <div>
        <DynamicForm formData={formData} />
      </div>
    );
  };
  
  export default GeneratedForm;
  
