import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from "@mui/material";
import {
  MdEdit,
  MdDelete,
  MdVisibility,
  MdVisibilityOff,
} from "react-icons/md";

const UserTable = ({ users, onDelete, onRoleChange }) => {
  return (
    <TableContainer sx={{ borderRadius: 2, overflow: "hidden", boxShadow: 2 }}>
      <Table>
        <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", color: "#1976d2" }}>
              Name
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#1976d2" }}>
              Email
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#1976d2" }}>
              Role
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#1976d2" }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Box sx={{ minWidth: "120px" }}>
                  <select
                    value={user.role}
                    onChange={(e) => onRoleChange(user.id, e.target.value)} // This triggers the role change
                    style={{
                      width: "100%",
                      padding: "6px",
                      borderRadius: "4px",
                      border: "1px solid #ddd",
                      fontSize: "14px",
                    }}
                  >
                    <option value="manager">Manager</option>
                    <option value="staff">Staff</option>
                  </select>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <IconButton
                    onClick={() => onDelete(user.id)} // Triggering the onDelete function passed from the parent
                    aria-label="Delete user"
                  >
                    <MdDelete color="red" />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
