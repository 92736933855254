import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token'); // Récupérer le token

  // Si le token est présent, accéder à la page protégée, sinon rediriger vers la page de connexion
  return token ? children : <Navigate to="/" />;
};

export default PrivateRoute;
