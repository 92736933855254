import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md"; // Icon for add button
import {
  Button,
  Modal,
  TextField,
  Box,
  Switch,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  IconButton,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "../Services/axios"; // Use your configured axios instance
import DynamicTable from "../Components/DynamicTable";
import "./CampaignsPage.css"; // Custom styles
import API_URL from "../config/config";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";

const CampaignsPage = () => {
  const [open, setOpen] = useState(false); // State to manage modal visibility for creating a campaign
  const [editOpen, setEditOpen] = useState(false); // State to manage modal visibility for editing a campaign
  const [formName, setFormName] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [campaigns, setCampaigns] = useState([]); // State for campaigns
  const [loading, setLoading] = useState(false); // Loading state for data fetching
  const [isActive, setIsActive] = useState(true); // State for campaign status
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message
  const [currentCampaignId, setCurrentCampaignId] = useState(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    total: 0,
    perPage: 10,
  });

  // Fetch campaigns when the component mounts
  useEffect(() => {
    fetchCampaigns(pagination.currentPage);
  }, [pagination.currentPage]);

  const fetchCampaigns = async (page) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/campaigns?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCampaigns(response.data.data); // Set campaigns data
      setPagination({
        currentPage: response.data.meta.current_page,
        lastPage: response.data.meta.last_page,
        total: response.data.meta.total,
        perPage: response.data.meta.per_page,
      });
    } catch (error) {
      console.error("Error fetching campaigns: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handlePageChange = (event) => {
    setPagination((prev) => ({ ...prev, currentPage: event.target.value }));
  };

  // Open modal for creating a new campaign
  const handleOpen = () => {
    setOpen(true);
    setIsActive(true); // Reset status when opening the modal
  };

  // Open modal for editing a campaign
  // Open modal for editing a campaign and fetch campaign data
  const handleEditOpen = async (campaign) => {
    setCurrentCampaignId(campaign); // Set the current campaign ID
    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const response = await axios.get(`${API_URL}/campaigns/${campaign}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });

      // Populate the form fields with the fetched campaign data
      setFormName(response.data.name);
      setFormDescription(response.data.description);
      setStartDate(response.data.start_date);
      setEndDate(response.data.end_date);
      setIsActive(response.data.status === "active"); // Set status based on campaign data
      setEditOpen(true); // Open edit modal
    } catch (error) {
      console.error("Error fetching campaign data: ", error);
      // Optionally, you can set an error message to show in the UI
    }
  };

  // Close both modals
  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setFormName("");
    setFormDescription("");
    setStartDate("");
    setEndDate("");
    setDateError("");
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this campaign?"
    );
    if (confirmDelete) {
      try {
        const token = localStorage.getItem("token"); // Get the token from localStorage
        await axios.delete(`${API_URL}/campaigns/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Refresh campaigns list after successful deletion
        await fetchCampaigns();
        setSnackbarMessage("Campaign deleted successfully!");
        setSnackbarOpen(true); // Open the snackbar for notification
      } catch (error) {
        console.error("Error deleting campaign: ", error);
        setSnackbarMessage("Error deleting campaign!");
        setSnackbarOpen(true); // Open the snackbar for error notification
      }
    }
  };

  // Form submit handler with validation for dates
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Date validation: End Date should be greater than Start Date
    if (new Date(endDate) <= new Date(startDate)) {
      setDateError("End Date must be greater than Start Date.");
      return;
    } else {
      setDateError(""); // Clear the error if dates are valid
    }

    const campaignData = {
      name: formName,
      description: formDescription,
      status: isActive ? "active" : "inactive", // Set status based on the toggle
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      if (currentCampaignId) {
        await axios.put(
          `${API_URL}/campaigns/${currentCampaignId}`,
          campaignData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          }
        );
        setSnackbarMessage("Campaign updated successfully!"); // Snackbar message for update
      } else {
        // Otherwise, create a new campaign
        await axios.post(`${API_URL}/campaigns`, campaignData, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        setSnackbarMessage("Campaign created successfully!"); // Snackbar message for creation
      }

      // Refresh campaigns list after successful submission
      await fetchCampaigns();
      handleClose(); // Close the modal after submission
    } catch (error) {
      console.error("Error creating/updating campaign: ", error);
      // Optionally, you can set an error message to show in the UI
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close the snackbar
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Gestion des Campagnes
      </Typography>

      {/* Bouton pour ajouter une nouvelle campagne */}
      <Button onClick={handleOpen} variant="contained"  sx={{
                  background:
                    "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                  ...buttonStyle,
                }}>
        <MdAdd style={{ marginRight: "8px" }} />
        Ajouter une Campagne
      </Button>

      {/* Afficher le spinner de chargement pendant que les données sont récupérées */}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "80px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Si aucune campagne, afficher un message */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <IconButton
              onClick={handlePreviousPage}
              disabled={pagination.currentPage === 1 || loading}
            >
              <NavigateBefore />
            </IconButton>

            <Select
              value={pagination.currentPage}
              onChange={handlePageChange}
              sx={{ minWidth: 100, mx: 2 }}
            >
              {Array.from({ length: pagination.lastPage }, (_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>

            <IconButton
              onClick={handleNextPage}
              disabled={
                pagination.currentPage === pagination.lastPage || loading
              }
            >
              <NavigateNext />
            </IconButton>
          </Box>
          {campaigns.length === 0 ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <p>
                Aucune campagne disponible. Veuillez en créer une pour commencer
                !
              </p>
            </div>
          ) : (
            <DynamicTable
              data={campaigns}
              enableCheckboxes={false}
              enableActions={true}
              onEdit={handleEditOpen} // Lien vers la nouvelle fonction handleEditOpen
              onDelete={handleDelete} // Passer la fonction handleDelete
            />
          )}
        </>
      )}

      {/* Modal pour créer une nouvelle campagne */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <h2>Créer une Nouvelle Campagne</h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Nom"
              required
              fullWidth
              margin="normal"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              sx={inputStyle}
            />
            <TextField
              label="Description"
              multiline
              fullWidth
              margin="normal"
              value={formDescription}
              onChange={(e) => setFormDescription(e.target.value)}
              sx={inputStyle}
            />
            <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
              <TextField
                label="Date de Début"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={inputStyle}
              />
              <TextField
                label="Date de Fin"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                sx={inputStyle}
                error={!!dateError}
                helperText={dateError}
              />
            </div>

            {/* Toggle pour le statut de la campagne */}
            <div style={{ marginTop: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                    color="primary"
                  />
                }
                label={isActive ? "Active" : "Inactive"}
              />
            </div>

            {/* Bouton de soumission */}
            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
             
                sx={{
                  background:
                    "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                  ...buttonStyle,
                }}
              >
                {currentCampaignId
                  ? "Mettre à Jour la Campagne"
                  : "Créer la Campagne"}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>

      {/* Modal pour modifier une campagne */}
      <Modal open={editOpen} onClose={handleClose}>
        <Box sx={modalStyle}>
          <h2>Modifier la Campagne</h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Nom"
              required
              fullWidth
              margin="normal"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              sx={inputStyle}
            />
            <TextField
              label="Description"
              multiline
              fullWidth
              margin="normal"
              value={formDescription}
              onChange={(e) => setFormDescription(e.target.value)}
              sx={inputStyle}
            />
            <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
              <TextField
                label="Date de Début"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={inputStyle}
              />
              <TextField
                label="Date de Fin"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                sx={inputStyle}
                error={!!dateError}
                helperText={dateError}
              />
            </div>

            {/* Toggle pour le statut de la campagne */}
            <div style={{ marginTop: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                    color="primary"
                  />
                }
                label={isActive ? "Active" : "Inactive"}
              />
            </div>

            {/* Bouton de soumission */}
            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                  ...buttonStyle,
                }}
              >
                Mettre à Jour la Campagne
              </Button>
            </div>
          </form>
        </Box>
      </Modal>

      {/* Snackbar pour notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

// Modal styles using MUI's sx prop
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

// Custom input styles for reduced height and centered placeholders
const inputStyle = {
  "& .MuiInputBase-input": {
    height: "30px", // Reduce input height
    padding: "6px 12px", // Center placeholder text
  },
};
const buttonStyle = {
  marginTop: "20px",
};

export default CampaignsPage;
