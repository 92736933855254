import React, { useState } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'; // Icons for actions
import './DynamicTable.css';

const DynamicTable = ({ data, enableCheckboxes, enableActions, onEdit, onDelete, onRowClick }) => {
  const [selectedRows, setSelectedRows] = useState(new Set());

  const handleCheckboxChange = (rowId) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(rowId)) {
      newSelectedRows.delete(rowId);
    } else {
      newSelectedRows.add(rowId);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = data.map((row) => row.id);
      setSelectedRows(new Set(allIds));
    } else {
      setSelectedRows(new Set());
    }
  };

  if (data.length === 0) {
    return <p>No Data available...</p>;
  }

  // Collect unique keys from all rows
  const allKeys = [...new Set(data.flatMap(Object.keys))];

  const getShortMessageHTML = (message) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = message; // Convert HTML to text
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.split(' ');
    const shortText = words.slice(0, 20).join(' ');
    return shortText + (words.length > 20 ? '...' : '');
  };

  const renderMessageHTML = (message) => {
    const shortMessage = getShortMessageHTML(message);
    return `<span>${shortMessage}</span>`;
  };

  return (
    <table className="custom-table">
      <thead>
        <tr>
          {enableCheckboxes && (
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedRows.size === data.length}
                indeterminate={selectedRows.size > 0 && selectedRows.size < data.length}
              />
            </th>
          )}
          {/* Render dynamically collected headers */}
          {allKeys.map((key) => (
            <th key={key}>{key.charAt(0).toLowerCase() + key.slice(1)}</th>
          ))}
          {enableActions && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id} onClick={() => onRowClick && onRowClick(row)}>
            {enableCheckboxes && (
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.has(row.id)}
                  onChange={() => handleCheckboxChange(row.id)}
                />
              </td>
            )}
            {/* Render dynamically collected cells */}
            {allKeys.map((key) => (
              <td key={key}>
                {key === 'message' ? (
                  <span dangerouslySetInnerHTML={{ __html: renderMessageHTML(row[key] || '') }} />
                ) : (
                  row[key] !== undefined ? row[key] : '-' // Display '-' if the key doesn't exist
                )}
              </td>
            ))}
            {enableActions && (
              <td>
                <button
                  style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                  onClick={(e) => { e.stopPropagation(); onEdit(row.id); }}
                >
                  <AiOutlineEdit size={20} color="blue" />
                </button>
                <button
                  style={{ background: 'transparent', border: 'none', cursor: 'pointer', marginLeft: '15px' }}
                  onClick={(e) => { e.stopPropagation(); onDelete(row.id); }}
                >
                  <AiOutlineDelete size={20} color="red" />
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;
