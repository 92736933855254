import React, { useState, useEffect } from "react";
import axios from "../Services/axios";
import { AiOutlineDelete } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import {
  Modal,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./DynamicTable.css";
import API_URL from "../config/config";

const DynamicTableForm = ({ formsData, setFormsData, enableCheckboxes, enableActions }) => {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [copyMessage, setCopyMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [formName, setFormName] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [status, setStatus] = useState("Inactive");
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  useEffect(() => {
    const fetchEmailTemplates = async () => {
      try {
        const response = await axios.get(`${API_URL}/email-templates`);
        setEmailTemplates(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Failed to fetch email templates:", error);
      }
    };

    if (editOpen) {
      fetchEmailTemplates();
    }
  }, [editOpen]);

  // New function to fetch the forms data
  const fetchFormsData = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms`);
      setFormsData(response.data); // Update the formsData state
    } catch (error) {
      console.error("Failed to fetch forms data:", error);
    }
  };

  if (!Array.isArray(formsData) || formsData.length === 0) {
    return <p>No data available.</p>;
  }

  const handleCheckboxChange = (rowId) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(rowId)) {
      newSelectedRows.delete(rowId);
    } else {
      newSelectedRows.add(rowId);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = formsData.map((row) => row.id);
      setSelectedRows(new Set(allIds));
    } else {
      setSelectedRows(new Set());
    }
  };

  const headers = Object.keys(formsData[0]);

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopyMessage("URL copied to clipboard!");
      setTimeout(() => setCopyMessage(""), 2000);
    });
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this form?");
    if (!confirmed) return;
  
    try {
      await axios.delete(`${API_URL}/forms/${id}`);
      // Update the state by filtering out the deleted form
      setFormsData((prevForms) => prevForms.filter((form) => form.id !== id));
    } catch (error) {
      setErrorMessage("Failed to delete. Please try again.");
      console.error("Delete error:", error);
    }
  };
  

  const handleRowClick = (row, event) => {
    if (event.target.closest(".copy-button")) return;

    setCurrentForm(row);
    setFormName(row.name || "");
    setFormDescription(row.description || "");
    setStatus(row.isActive ? "Active" : "Inactive");
    setEditOpen(true);
    setSelectedTemplateId(""); // Reset selected template on open
  };

  const handleModalClose = () => {
    setEditOpen(false);
    setSelectedTemplateId(""); // Reset selected template when closing modal
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    const updatedFormData = {
      name: formName,
      description: formDescription,
      email_template_id: selectedTemplateId,
      sms_template_id: null, // Set SMS template ID to null
    };
  
    try {
      await axios.put(`${API_URL}/forms/${currentForm.id}`, updatedFormData);
      // Update the state by mapping through existing forms and replacing the updated one
      setFormsData((prevForms) =>
        prevForms.map((form) =>
          form.id === currentForm.id ? { ...form, ...updatedFormData } : form
        )
      );
      handleModalClose(); // Close the modal after updating
    } catch (error) {
      setErrorMessage("Failed to update the form. Please try again.");
      setTimeout(() => setErrorMessage(""), 2000);
    }
  };
  

  return (
    <>
      <div className="table-responsive">
        <table className="custom-table">
          <thead>
            <tr>
              {enableCheckboxes && (
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows.size === formsData.length}
                    indeterminate={
                      selectedRows.size > 0 &&
                      selectedRows.size < formsData.length
                    }
                  />
                </th>
              )}
              {headers.map((key) => (
                <th key={key}>{key.charAt(0).toLowerCase() + key.slice(1)}</th>
              ))}
              {enableActions && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {formsData.map((row) => (
              <tr key={row.id} onClick={(e) => handleRowClick(row, e)}>
                {enableCheckboxes && (
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.has(row.id)}
                      onChange={() => handleCheckboxChange(row.id)}
                    />
                  </td>
                )}
                {headers.map((key) => (
                  <td key={key}>
                    {key === "url" ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "8px" }}>
                          {row[key].length > 20 ? `${row[key].slice(0, 20)}...` : row[key]}
                        </span>
                        <button
                          className="copy-button"
                          style={{ background: "transparent", border: "none", cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(row[key]);
                          }}
                        >
                          <MdContentCopy size={20} color="blue" />
                        </button>
                      </div>
                    ) : (
                      row[key]
                    )}
                  </td>
                ))}
                {enableActions && (
                  <td>
                    <button
                      style={{ background: "transparent", border: "none", cursor: "pointer", marginLeft: "10px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(row.id);
                      }}
                    >
                      <AiOutlineDelete size={20} color="red" />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {copyMessage && <div style={{ color: "green", marginTop: "10px" }}>{copyMessage}</div>}
      {errorMessage && <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>}

      <Modal open={editOpen} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <h2>Edit Form</h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Name"
              required
              fullWidth
              margin="normal"
              value={formName}
              sx={inputStyle}
              onChange={(e) => setFormName(e.target.value)}
            />
            <TextField
              label="Description"
              multiline
              fullWidth
              margin="normal"
              value={formDescription}
              sx={inputStyle}
              onChange={(e) => setFormDescription(e.target.value)}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Email Template</InputLabel>
              <Select
                value={selectedTemplateId}
                onChange={(e) => setSelectedTemplateId(e.target.value)}
                label="Email Template"
              >
                {emailTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                Update Form
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const inputStyle = {
  "& .MuiInputBase-input": {
    height: "30px",
    padding: "8px",
  },
  "& .MuiInputLabel-root": {
    lineHeight: "1",
  },
};

export default DynamicTableForm;
