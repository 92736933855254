import React from 'react';
import './StatusBox.css';

const StatusBox = ({ status }) => {
  let backgroundColor = '';
  let borderColor = '';
  let textColor = ''; // Variable for the text color

  // Switch case to determine the background and border colors based on status
  switch (status) {

    case 'Faux numéro':
      backgroundColor = '#f1cbff'; // Red-Orange
      borderColor = '#c323ff'; // Darker red-orange
      textColor = '#c323ff'; // Same as border color
      break;
  
    case 'Injoignable':
      backgroundColor = '#b1ffce'; // green
      borderColor = '#5bba63'; // Orange
      textColor = '#5bba63'; // Same as border color
      break;
   
    case 'Pas intéressé':
      backgroundColor = '#ffcccc'; // red
      borderColor = '#ff5252'; // Dark Gray
      textColor = '#ff5252'; // Same as border color
      break;
   
    case 'Réponse positive':
      backgroundColor = '#d1deff'; // blue
      borderColor = '#5799ff'; // Forest Green
      textColor = '#5799ff'; // Same as border color
      break;
      
    case 'À rappeler':
      backgroundColor = '#c3eeff'; // Dodger Blue
      borderColor = '#53cbff'; // Steel Blue
      textColor = '#53cbff'; // Same as border color
      break;
    default:

      backgroundColor = '#c3eeff'; // Default gray for null or unknown status
      borderColor = '#53cbff'; // Default dark gray
      textColor = '#53cbff'; // Same as border color
  }

  // If status is null or empty, apply the default styling
  if (!status) {
    backgroundColor = '#c3eeff'; // Gray for null or undefined status
    borderColor = '#53cbff'; // Dark gray border
    textColor = '#53cbff'; // Dark gray text
  }

  return (
    <div
      className="status-box"
      style={{
        backgroundColor,
        borderColor,
        color: textColor, 
        fontSize:"14px",
        fontWeight:300,
      }}
    >
      {status || 'Non spécifié'} {/* Default text if status is null or empty */}
    </div>
  );
};

export default StatusBox;
