import React, { useEffect, useState } from "react";
import { MdAdd, MdRemove } from "react-icons/md";
import {
  Button,
  Modal,
  TextField,
  Box,
  MenuItem,
  Typography,
  Switch,
  FormControlLabel,
  IconButton,
  Select,
} from "@mui/material";
import DynamicTableForm from "../Components/DynamicTableForm";
import axios from "../Services/axios"; // Use your configured axios instance
import "./CampaignsPage.css";
import API_URL from "../config/config";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import CircularProgress from "@mui/material/CircularProgress";


const FormPage = () => {
  const [open, setOpen] = useState(false);
  const [formName, setFormName] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [fields, setFields] = useState([{ label: "", dropdownValue: "" }]);
  const [formsData, setFormsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState("");
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState("");
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [facebookEnabled, setFacebookEnabled] = useState(false);
  const [facebookPages, setFacebookPages] = useState([]);
  const [selectedFacebookPage, setSelectedFacebookPage] = useState("");
  const [facebookForms, setFacebookForms] = useState([]);
  const [selectedFacebookForm, setSelectedFacebookForm] = useState("");
  const [fieldsEditable, setFieldsEditable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);



  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    total: 0,
    perPage: 2,
  });
  const [loadingPage, setLoadingPage] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    fetchAllCampaigns();
    fetchEmailTemplates();
    fetchSmsTemplates();
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
  };

  const handleAddField = () => {
    setFields([...fields, { label: "", dropdownValue: "" }]);
  };

  const handleRemoveField = (index) => {
    if (fields.length > 1) {
      const newFields = fields.filter((_, i) => i !== index);
      setFields(newFields);
    }
  };

  const handleFieldChange = (index, event) => {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const requestBody = {
      name: formName,
      campaign_id: selectedCampaign,
      description: formDescription,
      email_template_id: selectedEmailTemplate || null,
      sms_template_id: selectedSmsTemplate || null,
      fields: fields.map((field) => ({
        label: field.label,
        type: field.dropdownValue,
      })),
      enabled_web: true, // Always set to true
      enabled_facebook: facebookEnabled, // Ensure this is correctly set
      page_name: selectedFacebookPage
        ? facebookPages.find((page) => page.id === selectedFacebookPage)?.name
        : "",
      page_id: selectedFacebookPage || "",
      form_name: selectedFacebookForm
        ? facebookForms.find((form) => form.id === selectedFacebookForm)?.name
        : "",
      form_id: selectedFacebookForm || "",
    };

    try {
      const token = localStorage.getItem("token");
      await axios.post(`${API_URL}/formsV2`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Form created successfully");
      fetchFormsData();
      handleClose();
    } catch (error) {
      console.error("Error creating form: ", error);
    }
    finally {
      setIsSubmitting(false); // Reset loading state
      handleClose();
  }
  };

  
  const fetchFormsData = async (page = 1) => {
    setLoadingPage(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/forms?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFormsData(response.data.data);
      console.log(formsData);
      setPagination({
        currentPage: response.data.meta.current_page,
        lastPage: response.data.meta.last_page,
        total: response.data.meta.total,
        perPage: response.data.meta.per_page,
      });
    } catch (error) {
      console.error("Error fetching forms data: ", error);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.lastPage) {
      fetchFormsData(pagination.currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      fetchFormsData(pagination.currentPage - 1);
    }
  };

  const handlePageChange = (event) => {
    fetchFormsData(event.target.value);
  };

  useEffect(() => {
    fetchFormsData(pagination.currentPage);
  }, [pagination.currentPage]);

  const fetchAllCampaigns = async () => {
    try {
      const token = localStorage.getItem("token");
      let allCampaigns = [];
      let currentPage = 1;
      let lastPage = 1;

      do {
        const response = await axios.get(
          `${API_URL}/campaigns?page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Append the fetched campaigns to the allCampaigns array
        allCampaigns = [...allCampaigns, ...response.data.data];
        // Update pagination state
        currentPage = response.data.meta.current_page + 1;
        lastPage = response.data.meta.last_page;
      } while (currentPage <= lastPage);

      setCampaigns(allCampaigns); // Set the state with all campaigns
    } catch (error) {
      console.error("Error fetching campaigns: ", error);
    }
  };

  const fetchEmailTemplates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/email-templates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEmailTemplates(response.data);
    } catch (error) {
      console.error("Error fetching email templates: ", error);
    }
  };

  const fetchSmsTemplates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/sms-templates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSmsTemplates(response.data);
    } catch (error) {
      console.error("Error fetching SMS templates: ", error);
    }
  };

  const handleFacebookToggle = async (checked) => {
    setFacebookEnabled(checked); // Update the state
    console.log(facebookEnabled);
    if (checked) {
      await fetchFacebookPages(); // Fetch pages only if enabling
      setFieldsEditable(false); // Disable fields if Facebook is connected
    } else {
      // Reset relevant states when disabling
      setFacebookPages([]);
      setSelectedFacebookPage("");
      setFieldsEditable(true); // Allow editing again
      setFacebookForms([]);
      setSelectedFacebookForm("");
    }
  };

  // Log state change in a separate effect
  useEffect(() => {
    console.log(facebookEnabled);
  }, [facebookEnabled]);

  const fetchFacebookPages = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/facebook/pages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFacebookPages(response.data.data);
    } catch (error) {
      console.error("Error fetching Facebook pages: ", error);
    }
  };

  const fetchFacebookForms = async (pageId) => {
    try {
      const response = await axios.get(
        `${API_URL}/facebook/forms/pages/${pageId}`
      );
      setFacebookForms(response.data.data);
    } catch (error) {
      console.error("Error fetching Facebook forms: ", error);
    }
  };

  const handleFacebookPageChange = (pageId) => {
    setSelectedFacebookPage(pageId);
    fetchFacebookForms(pageId);
  };

  const handleFacebookFormChange = async (formId) => {
    setSelectedFacebookForm(formId);
    try {
      const response = await axios.get(`${API_URL}/facebook/forms/${formId}`);
      const { name, fields } = response.data;
      setFormName(name);
      setFields(
        fields.map((field) => ({
          label: field.label,
          dropdownValue: field.type,
        }))
      );
    } catch (error) {
      console.error("Error fetching Facebook form details: ", error);
    }
  };

  useEffect(() => {
    fetchFormsData();
  }, []);

  const resetFormFields = () => {
    setFormName("");
    setFormDescription("");
    setFields([{ label: "", dropdownValue: "" }]);
    setSelectedCampaign("");
    setEmailEnabled(false);
    setSelectedEmailTemplate("");
    setSmsEnabled(false);
    setSelectedSmsTemplate("");
    setFacebookEnabled(false);
    setSelectedFacebookPage("");
    setFacebookForms([]);
    setSelectedFacebookForm("");
    setFieldsEditable(true);
  };

 
  

  return (
    <Box sx={{ p: 4 ,maxWidth:"100%"}}>
      <Typography variant="h4" gutterBottom>
        Gestion des Formulaires
      </Typography>

      <Button onClick={handleOpen} variant="contained" sx={{
                  background:
                    "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                }}>
        <MdAdd style={{ marginRight: "8px" }} />
        Ajouter un Formulaire
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <h2>Nouveau Formulaire</h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Nom du Formulaire"
              required
              fullWidth
              margin="normal"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              sx={inputStyle}
            />
            <TextField
              label="Description"
              required
              fullWidth
              multiline
              margin="normal"
              value={formDescription}
              onChange={(e) => setFormDescription(e.target.value)}
              sx={inputStyle}
            />

            <TextField
              select
              label="Sélectionner une Campagne"
              required
              fullWidth
              margin="normal"
              value={selectedCampaign}
              onChange={(e) => setSelectedCampaign(e.target.value)}
              sx={inputStyle}
            >
              {campaigns.map((campaign) => (
                <MenuItem key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </TextField>

            {/* Section Email */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={emailEnabled}
                    onChange={(e) => setEmailEnabled(e.target.checked)}
                    color="primary"
                  />
                }
                label="Envoyer un Email"
              />
              <TextField
                select
                label="Sélectionner un Modèle d'Email"
                disabled={!emailEnabled}
                value={selectedEmailTemplate}
                onChange={(e) => setSelectedEmailTemplate(e.target.value)}
                sx={{ marginLeft: "10px", flexGrow: 1 }}
              >
                {emailTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {/* Section SMS */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={smsEnabled}
                    onChange={(e) => setSmsEnabled(e.target.checked)}
                    color="primary"
                  />
                }
                label="Envoyer un SMS"
              />
              <TextField
                select
                label="Sélectionner un Modèle de SMS"
                disabled={!smsEnabled}
                value={selectedSmsTemplate}
                onChange={(e) => setSelectedSmsTemplate(e.target.value)}
                sx={{ marginLeft: "10px", flexGrow: 1 }}
              >
                {smsTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div style={{ marginTop: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={facebookEnabled}
                    onChange={(e) => handleFacebookToggle(e.target.checked)}
                    color="primary"
                  />
                }
                label="Connecter Facebook"
              />
            </div>

            {/* Section Facebook */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <TextField
                select
                label="Sélectionner une Page Facebook"
                disabled={!facebookEnabled}
                value={selectedFacebookPage}
                onChange={(e) => handleFacebookPageChange(e.target.value)}
                sx={{ marginLeft: "10px", flexGrow: 1 }}
              >
                {facebookPages.map((page) => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                label="Sélectionner un Formulaire Facebook"
                disabled={!selectedFacebookPage}
                value={selectedFacebookForm}
                onChange={(e) => handleFacebookFormChange(e.target.value)}
                sx={{ marginLeft: "10px", flexGrow: 1 }}
              >
                {facebookForms.map((form) => (
                  <MenuItem key={form.id} value={form.id}>
                    {form.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <h3>Champs du Formulaire</h3>
            {fields.map((field, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <TextField
                  name="label"
                  label="Étiquette du Champ"
                  value={field.label}
                  onChange={(e) => handleFieldChange(index, e)}
                  disabled={!fieldsEditable}
                  required
                  sx={{ marginRight: "10px", flexGrow: 1 }}
                />
                <TextField
                  name="dropdownValue"
                  label="Type de Champ"
                  select
                  value={field.dropdownValue}
                  disabled={!fieldsEditable}
                  onChange={(e) => handleFieldChange(index, e)}
                  sx={{ marginRight: "10px", width: "200px", height: "56px" }}
                  required
                >
                  <MenuItem value="TEXT">Texte</MenuItem>
                  <MenuItem value="FULL_NAME">Nom Complet</MenuItem>
                  <MenuItem value="FIRST_NAME">Prénom</MenuItem>
                  <MenuItem value="LAST_NAME">Nom de Famille</MenuItem>
                  <MenuItem value="EMAIL">Email</MenuItem>
                  <MenuItem value="PHONE">Numéro de Téléphone</MenuItem>
                  <MenuItem value="CITY">Ville</MenuItem>
                </TextField>
                <Button
                  onClick={() => fieldsEditable && handleRemoveField(index)}
                  variant="outlined"
                  color="secondary"
                >
                  <MdRemove />
                </Button>
              </div>
            ))}
            <Button
              onClick={() => fieldsEditable && handleAddField()}
              variant="outlined"
              sx={{
                background:
                  "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
              }}
              style={{ marginTop: "10px",color:'white' }}
              
            >
              Ajouter un Champ
            </Button>
            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                }}
                style={{ marginTop: "20px" }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Création en cours..." : "Soumettre"}
              </Button>
              {isSubmitting && <CircularProgress />}
            </div>
          </form>
        </Box>
      </Modal>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
          flexWrap: "wrap", // Ensure the layout wraps in smaller screens
          gap: 2, 
          minWidth:"80%"
        }}
      >
        <IconButton
          onClick={handlePreviousPage}
          disabled={pagination.currentPage === 1 || loadingPage}
        >
          <NavigateBefore />
        </IconButton>

        <Select
          value={pagination.currentPage}
          onChange={handlePageChange}
          sx={{
            minWidth: 20,
            mx: 2,
            height:"40px",
            borderRaduis:"12px",
            flexShrink: 0,
      
          }}
        >
          {Array.from({ length: pagination.lastPage }, (_, index) => (
            <MenuItem key={index + 1} value={index + 1}>
              {index + 1}
            </MenuItem>
          ))}
        </Select>

        <IconButton
          onClick={handleNextPage}
          disabled={
            pagination.currentPage === pagination.lastPage || loadingPage
          }
        >
          <NavigateNext />
        </IconButton>
      </Box>

      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <DynamicTableForm
          formsData={formsData}
          setFormsData={setFormsData}
          loading={loading}
          enableActions={true}
        />
      </Box>

      
    </Box>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const inputStyle = {
  "& .MuiInputBase-input": {
    height: "30px",
    padding: "8px",
  },
  "& .MuiInputLabel-root": {
    lineHeight: "1",
  },
};

export default FormPage;
