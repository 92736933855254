import React, { useRef, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Typography, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Chart, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, Filler, CategoryScale } from 'chart.js';

Chart.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, Filler, CategoryScale);

const AreaLineChart = ({ title, data }) => {
  const canvasRef = useRef(null);
  const [gradient, setGradient] = useState(null);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      const grd = ctx.createLinearGradient(0, 0, 0, 400);
      grd.addColorStop(0, 'rgba(106, 90, 205, 0.3)');
      grd.addColorStop(1, 'rgba(106, 90, 205, 0.1)');

      setGradient(grd);
    }
  }, []);

  return (
    <Box
      sx={{
        minWidth: 'auto',
        height: 400,
        margin: 1,
        padding: 3,
        borderRadius: 4,
        backgroundColor: '#353a50',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography variant="h6" sx={{ flexGrow: 1 ,color:'white'}}>
          {title}
        </Typography>
        <InfoIcon sx={{ color: 'white', marginLeft: 1 }} />
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <div style={{ height: '90%', width: 'auto'}}>
        <Line 
          data={{
            labels: data.labels,
            datasets: [
              {
                label: 'Leads Facebook', // Label for the first line
                data: data.datasets[0].data, // Data for the first line
                borderColor: 'white', // Color for the first line
                borderWidth:4,
                backgroundColor: 'transparent', // Optional: apply gradient
                fill: true, // This makes it an area chart
              },
              {
                label: 'Landing page', // Label for the second line
                data: data.datasets[1].data, // Data for the second line
                borderColor: '#2eb7ff', // Color for the second line
                borderWidth:4,
                backgroundColor: 'transparent', // Optional: apply gradient
                fill: true, // This makes it an area chart
              },
            ],
          }} 
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  display: true,
                },
              },
              y: {
                grid: {
                  display: false,
                  borderColor: 'white',
                  color: 'white',
                  drawBorder: true,
                },
              },
            },
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                  },
                },
              },
            },
            height: 200,
          }} 
        />
      </div>
    </Box>
  );
};

export default AreaLineChart;
